import React from "react";
import { useSelector } from "react-redux";

import Hero from "../emprendimientos/hero-slider";

function Developments() {
  const { data } = useSelector((store) => store.developments.development);

  return (
    <div className="oportunity">
      <div className="border-bottom-secondary"></div>
      <Hero />
    </div>
  );
  // : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default Developments;
