import React , {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import { getDevelopmentsAction } from '../redux/developmentsDucks';


// -- Componentes -- //
import Hero from '../components/inicio/hero'
import Oportunity from '../components/inicio/oportunity'
import About from '../components/inicio/about'
import Services from '../components/inicio/services'
import Novelties from '../components/inicio/novelties'

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}

function Inicio(){

    const dispatch = useDispatch();
    useEffect(() => {
        const getDevs = () => {
            dispatch(getDevelopmentsAction())
        }
        getDevs()
    }, [])

    ScrollToTopOnMount()
    return(
        <div className="Inicio">
            <Hero /> 
            <Oportunity />
            <About />
            <Services />  
            <Novelties />   
        </div>
    )
}

export default Inicio
