import React from "react";
import { useSelector } from "react-redux";

function Files() {
  const settings = useSelector((store) => store.settings);
  const dark = useSelector((store) => store.theme.dark);

  return (
    <div className={"main " + (dark ? "dark-theme" : '')}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6">
            <h1 className="title-1 text-center text-lg-start">¡HABLEMOS!</h1> 
          </div>
          <div className="col-lg-6 d-flex justify-content-lg-end">
            <span>
              completá <br />
              el formulario
            </span>
          </div>
          <div className="col-12 px-4 mt-4 mt-lg-0">
            <div className="row">
              <div className="col-lg-3" id="start">
                <a href={`mailto:${settings.contact.mail}`} className="mail">
                  {settings.contact.mail}
                </a>
              </div>
              <div
                className="col-lg-3 d-flex justify-content-lg-center"
                id="middle"
              >
                {settings.contact.phone}
              </div>
              <div
                className="col-lg-6 d-flex justify-content-lg-end pe-lg-2"
                id="end"
              >
                {settings.location.address} /{" "}
                <div className="d-none d-lg-inline-block">
                  {settings.location.region}
                </div>
              </div>
              <div className="col-lg-6 d-flex d-lg-none pe-lg-2" id="end-flex">
                <div>{settings.location.region}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Files;
