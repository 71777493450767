import { data } from "jquery";
import { useRef, useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import Image1 from "../../assets/images/building-1.png";
import Image2 from "../../assets/images/aspa-group.png";
import Image3 from "../../assets/images/areas.png";
import Image4 from "../../assets/images/building-2.png";
import Image5 from "../../assets/images/building-3.png";

import MyPDF from '../../ASPA - Brochure Corporativo 2021.pdf';

import Image10 from '../../assets/images/- Pionero1.jpg'

import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import { useDispatch, useSelector } from "react-redux";
import { updateFields } from '../../redux/contactDucks'
import { formTokko } from '../../redux/contactDucks'
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios"

import ReactPlayer from "react-player";

import developments from './developments.json'

const options = {
  items: 1,
  // nav: true,
  rewind: true,
  dots: true,
  // center: true,
  startPosition: 0,
  // stageOuterClass: "py-10",
  navClass: ["slider-left-button", "slider-right-button"],
  responsive: {
    992: {
      items: 5,
    },
  },
};


function UsMain() {
  const slider = useRef();
  const trajectorySlider = useRef();
  const dispatch = useDispatch();
  const settings = useSelector((store) => store.settings);   
  const [text, setText] = useState("");
  const [disabled, setDisabled] = useState(true)
  const [selected,setSelected] = useState([])
  const [loading,setLoading] = useState(false)

  const dark = useSelector((store) => store.theme.dark);

  const [year,setYear] = useState(2000)

  const [datos, setDatos] = useState({
    nombre:'',
    apellido:'',
    cellphone:'',
    phone:'',
    email:'',
    work_name:'',
    text:'',
    properties:'',
    tags:''
  })
  const handleInputChange = (event) => {
    setDatos({
        ...datos,
        [event.target.name] : event.target.value, 
    })
  }
  const resetForm = (event) => {
  event.preventDefault();
  const data = new FormData(document.getElementById("formContact"));
  axios.post('https://mediahaus.com.ar/clientes/aspa/send.php',data)
  document.getElementById("formContact").reset();
  setText(
    "Su mensaje ha sido enviado satisfactoriamente. En breve responderemos su consulta."
  );
  };
  const ourCompany = [
  { number: "20", flavor: "años de experiencia" },
  { number: "50", flavor: "proyectos en portfolio" },
  { number: "288000", flavor: "m2 desarrollados y ejecución" },
  { number: "7470", flavor: "lotes desarrollados y ejecución" },
  { number: "10", flavor: "ciudades con desarrollos propios" },
  ];
  const ourCulture = [
  {
    name: "Misión",
    description:
      "Desarrollar proyectos inmobiliarios orientados a satisfacer las necesidades de vivienda e inversión actuales y potenciales de clientes e inversores, generando fuentes de trabajo a la comunidad y bienestar a las familias que en ellas habitan.",
    icon: "icon-arrow-target",
  },
  {
    name: "Visión",
    description:
      "Postular productos que impacten positivamente en la comunidad local y regional, proporcionando desarrollos que jerarquicen y modernicen las ciudades y sus alrededores.",
    icon: "icon-vision",
  },
  {
    name: "Propuesta de Valor",
    description:
      "Diseño de productos a medida. Niveles constructivos según niveles de inversión.",
    icon: "icon-book-rule",
  },
  {
    name: "Propósito",
    description:
      "Hacer de nuestra región un hábitat en el que puedan desarrollarse comunidades orientadas al triple impacto.",
    icon: "icon-flag",
  },
  {
    name: "Valores",
    description:
      "Familia + Equipo + Mejora continua + Confianza + Profesionalismo + Salud + Arte y cultura + Innovación Deportes + Bienestar.",
    icon: "icon-hand-shake",
  },
  {
    name: "Cambio constante",
    description:
      "Cambiar una conducta lleva años o simplemente una pandemia. Resulta una de las cosas más difíciles de hacer, de forma conjunta y de inmediato.",
    icon: "icon-circle-arrow",
  },
  {
    name: "Desarrollo inmobiliario",
    description:
      "Comprende entre tantos procesos al de la construcción, que es uno de los sectores productivos que más aporta al crecimiento de la economía de un país, porque integra a un gran número de personas y produce un efecto dinamizador.",
    icon: "icon-building-build",
  },
  {
    name: "Efecto multiplicador",
    description:
      "Se trata de una construcción colectiva que se traduce en nuevas oportunidades, que repercuten en satisfacer desde necesidades primarias hasta la mejora de la calidad de vida.",
    icon: "icon-team",
  },
  {
    name: "Oportunidades",
    description:
      "En contextos desfavorables e inciertos, Aspa siempre encontró espacios para crecer. Nuestra creatividad complementa a nuestras ventajas competitivas a la hora de salir adelante.",
    icon: "icon-dollar-location",
  },
  ];
  useEffect(() => {
    const update = () => {
        dispatch(updateFields(datos))
    }
    update()
  }, [datos])

  useEffect(() => {
    if(developments){
      setLoading(true)
      setSelected(developments.find(element => element.year === year))
      setTimeout(function(){setLoading(false)}, 100)
    }
  },[year])


  return (
    <div className={"us " + (dark ? "dark-theme" : "")}>
      <div className="container-fluid mt-5">
        <h1 className="text-center text-md-start title-1">nuestra empresa</h1>
        <div className="our-numbers">
          {ourCompany.map((text) => (
            <div className="ourCompanyContainer">
              <CountUp
                start={0}
                end={Number(text.number)}
                duration={7}
                delay={0}
                prefix="+"
                separator="."
              >
                {({ countUpRef, start }) => <h2 ref={countUpRef} />}
              </CountUp>
              <div className="d-flex justify-content-center">
                <p>{text.flavor}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="section_indicator mt-4">
        <div className="container-fluid  d-flex align-items-center justify-content-between">
          <span>NOSOTROS</span>
          <span>QUIÉNES SOMOS </span>
        </div>
        <div className="container-fluid mt-3">
          <div className="image1-parallax"></div>
          <div className="brochureContainer">
            <p className="paragraph-1 mt-1 mb-0">
              En 1999 decidimos asumir el desafío de crear en la Patagonia una
              organización que permitiera brindar un servicio integral y
              diferencial que hasta entonces no existía en la región y hoy crece
              en cada proyecto. Nos identificamos como una pyme que, con
              capitales propios, estructura el negocio, desarrolla, construye y
              vende productos inmobiliarios caracterizados por una impronta
              autóctona que conjuga innovación, calidad y servicios.
              <br />
              <br />
              Como desarrollistas nuestra labor está signada por la
              responsabilidad, el compromiso y la prudencia procurando lograr la
              mayor eficiencia. Atendemos a todas las personas que consideran
              invertir en un inmueble, asesorándolas en todos los aspectos que
              abarca el mismo.
            </p>
            <p className="paragraph-1 mt-1 mb-0">
              Construimos residencias familiares en lugares especiales, oficinas
              comerciales con ubicación estratégica, como así también complejos
              hoteleros y de usos mixtos.
              <br />
              <br />
              Cada día se pone en marcha un proceso que comprende todos los
              aspectos de la cadena productiva, acercando posiciones entre los
              diferentes actores (inversores, familias que buscan su vivienda,
              constructores, organismos reguladores, espacio, etc.), generando
              fuentes de trabajo y un efecto multiplicador en la economía
              regional.
              <br />
              <br />
              Alentados por la confianza que nos brindan nuestros clientes,
              inversores, equipo y socios estratégicos en la gestión, hemos
              transformado una porción importante del territorio del
            </p>
            <p className="paragraph-1 mt-1">
              Alto Valle de Río Negro y Neuquén, la villa balnearia Las Grutas y
              Viedma. Entendemos la importancia de la cercanía y del vínculo con
              todos los públicos afectados a nuestra empresa, propiciando las
              relaciones interpersonales en un marco de profesionalidad y
              respeto.
              <br  />
              <br />
              Todo esto es posible porque somos un equipo multidisciplinario e
              intercultural de profesionales con vasta experiencia y altamente
              capacitados. Nuestro equipo se conforma por jóvenes profesionales;
              MBA; MIM; expertos en Seguridad e Higiene, especialistas en
              Planificación Urbana; diplomados en Sustentabilidad.
              <br />
              <br />
              Formados en Escuelas de Negocios Nacionales e Internacionales.
            </p>
          </div>
          <div className="text-center text-md-start">
            <a download href={MyPDF}>
              <button className="brochureBtn mb-5">
                <div>
                  <p>descargar brochure</p> <i className="icon-upload" />
                </div>
              </button>
            </a>
          </div>
          <div className="filtered-image mt-0 mt-md-5">
            <ReactPlayer
              url={"https://youtu.be/1TvfsGZOtGA"}
              playing={true}
              width="100%"
              height="50vh"
              controls={false}
              playIcon={<img src="./assets/images/play.png" />}
              light={
                "https://img.youtube.com/vi/" +
                "1TvfsGZOtGA" +
                "/maxresdefault.jpg"
              }
            />
          </div>
        </div>
      </div>
      <div className="section_indicator_end">
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <span className="w-100">NOSOTROS</span>
          <span className="w-100">CONOCÉ COMO SOMOS </span>
        </div>
      </div>
      <div className="container-fluid mt-4 mt-md-5">
        <h2 className="text-center text-md-start title-2">
          nuestro sistema de creencias y cultura
        </h2>

        <div className="culture-slider">
          <button
            className="slider-right-button"
            onClick={() => {
              slider.current.next();
            }}
          >
            <i className="icon-arrow"></i>
          </button>
          <button
            className="slider-left-button"
            onClick={() => {
              slider.current.prev();
            }}
          >
            <i className="icon-arrow"></i>
          </button>
          <OwlCarousel ref={slider} {...options} className="owl-themes">
            {ourCulture.map((data_, i) => (
              <CultureItem
                key={data_.name}
                {...data_}
                isLast={i == ourCulture.length - 1}
              />
            ))}
          </OwlCarousel>
        </div>
      </div>
      <div className="section_indicator section_dark mt-3 mt-md-5">
        <div className="container-fluid  d-flex align-items-center justify-content-between">
          <span>NOSOTROS</span>
          <span>QUIÉNES SOMOS </span>
        </div>
        <div className="bg-dark">
          <div className="mt-3" style={{ borderTop: "solid 2px #fff" }}>
            <img
              src={Image2}
              height="550px"
              width="100%"
              style={{ objectFit: "cover" }}
              alt="edificio"
            />
            <iframe className="d-none d-lg-block" style={{overflow:"hidden", height:"80vh",width:"100%"}} src="burbujas.html" />
          </div>
          {/* <div>
            <img
              src={Image3}
              height="100%"
              width="100%"
              style={{ objectFit: "cover", minHeight:"300px"}}
              alt="edificio"
            />
          </div> */}
        </div>
        <div className="numbers-info ">
          <div>
            <h3 className="mb-0 mb-md-2">
              <CounterNumber value={16} />
              {/* <br /> */}
              ÁREAS in house
            </h3>
            <p className="mt-0">INTERDISCIPLINARIAS</p>
          </div>
          <div>
            <h3 className="mb-0 mb-md-2">
              <CounterNumber value={100} />
              {/* <br /> */}
              profesionales
            </h3>
            <p>en la estructura fija</p>
          </div>
          <div>
            <h3 className="mb-0 mb-md-2">
              <CounterNumber value={400} />
              {/* <br /> */}
              trabajadores
            </h3>
            <p>EN OBRA</p>
          </div>
        </div>
      </div>
      <div className="section_indicator_end mt-3 mt-md-5">
        <div className="container-fluid d-flex align-items-center justify-content-between">
          <span>NOSOTROS</span>
          <span>NUESTRA TRAYECTORIA </span>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row trajectory">
          <div className="col-lg-6 col-sm-12 pe-4">
            <h2>Trayectoria</h2>
            <h3>nuestos proyectos desde 2000</h3>
            <div className="table-years">
              <div>
                <span className={year === 2000 ? 'selected' : ''} onClick={() => setYear(2000)}><i className={"icon-light " + (year === 2000 ? "d-block" : "d-none")}></i>Año 2000</span>
                <span className={year === 2013 ? 'selected' : ''} onClick={() => setYear(2013)}><i className={"icon-light " + (year === 2013 ? "d-block" : "d-none")}></i>Año 2013</span>
              </div>
              <div>
                <span className={year === 2002 ? 'selected' : ''} onClick={() => setYear(2002)}><i className={"icon-light " + (year === 2002 ? "d-block" : "d-none")}></i>Año 2002</span>
                <span className={year === 2014 ? 'selected' : ''} onClick={() => setYear(2014)}><i className={"icon-light " + (year === 2014 ? "d-block" : "d-none")}></i>Año 2014</span>
              </div>
              <div>
                <span className={year === 2003 ? 'selected' : ''} onClick={() => setYear(2003)}><i className={"icon-light " + (year === 2003 ? "d-block" : "d-none")}></i>Año 2003</span>
                <span className={year === 2015 ? 'selected' : ''} onClick={() => setYear(2015)}><i className={"icon-light " + (year === 2015 ? "d-block" : "d-none")}></i>Año 2015</span>
              </div>
              <div>
                <span className={year === 2005 ? 'selected' : ''} onClick={() => setYear(2005)}><i className={"icon-light " + (year === 2005 ? "d-block" : "d-none")}></i>Año 2005</span>
                <span className={year === 2016 ? 'selected' : ''} onClick={() => setYear(2016)}><i className={"icon-light " + (year === 2016 ? "d-block" : "d-none")}></i>Año 2016</span>
              </div>
              <div>
                <span className={year === 2006 ? 'selected' : ''} onClick={() => setYear(2006)}><i className={"icon-light " + (year === 2006 ? "d-block" : "d-none")}></i>Año 2006</span>
                <span className={year === 2017 ? 'selected' : ''} onClick={() => setYear(2017)}><i className={"icon-light " + (year === 2017 ? "d-block" : "d-none")}></i>Año 2017</span>
              </div>
              <div>
                <span className={year === 2007 ? 'selected' : ''} onClick={() => setYear(2007)}><i className={"icon-light " + (year === 2007 ? "d-block" : "d-none")}></i>Año 2007</span>
                <span className={year === 2018 ? 'selected' : ''} onClick={() => setYear(2018)}><i className={"icon-light " + (year === 2018 ? "d-block" : "d-none")}></i>Año 2018</span>
              </div>
              <div>
                <span className={year === 2008 ? 'selected' : ''} onClick={() => setYear(2008)}><i className={"icon-light " + (year === 2008 ? "d-block" : "d-none")}></i>Año 2008</span>
                <span className={year === 2019 ? 'selected' : ''} onClick={() => setYear(2019)}><i className={"icon-light " + (year === 2019 ? "d-block" : "d-none")}></i>Año 2019</span>
              </div>
              <div>
                <span className={year === 2010 ? 'selected' : ''} onClick={() => setYear(2010)}><i className={"icon-light " + (year === 2010 ? "d-block" : "d-none")}></i>Año 2010</span>
                <span className={year === 2020 ? 'selected' : ''} onClick={() => setYear(2020)}><i className={"icon-light " + (year === 2020 ? "d-block" : "d-none")}></i>Año 2020</span>
              </div>
              <div>
                <span className={year === 2011 ? 'selected' : ''} onClick={() => setYear(2011)}><i className={"icon-light " + (year === 2011 ? "d-block" : "d-none")}></i>Año 2011</span>
                <span className={year === 2021 ? 'selected' : ''} onClick={() => setYear(2021)}><i className={"icon-light " + (year === 2021 ? "d-block" : "d-none")}></i>Año 2021</span>
              </div>
              <div>
                <span className={year === 2012 ? 'selected' : ''} onClick={() => setYear(2012)}><i className={"icon-light " + (year === 2012 ? "d-block" : "d-none")}></i>Año 2012</span>
                <span className={year === 9999 ? 'selected' : ''} onClick={() => setYear(9999)}><i className={"icon-light " + (year === 9999 ? "d-block" : "d-none")}></i>Proyección</span>
              </div>
            </div>
          </div>
          <div className="trajectory-slider col-lg-6 col-sm-12 mt-5 mt-md-0 ">
            <button
              className={"slider-right-button " + ((developments.find(element => element.year === year).developments).length === 1 ? 'd-none' : '')}
                onClick={() => {
                trajectorySlider.current.next();
              }}
            >
              <i className="icon-arrow"></i>
            </button>
            <button
              className={"slider-left-button " + ((developments.find(element => element.year === year).developments).length === 1 ? 'd-none' : '')}
              onClick={() => {
                trajectorySlider.current.prev();
              }}
            >
              <i className="icon-arrow"></i>
            </button>
            <OwlCarousel
              ref={trajectorySlider}
              items={1}
              // loop
              rewind={true}
              className="owl-themes"
            >
              {
                developments.find(element => element.year === year).developments.map((item,index) => (
                    <TrajectoryItem
                      name={item.name}
                      id={index}
                      value={item.mts}
                      measure={item.lotes ? "Lotes" :"Metros cuadrados"}
                      location={item.location}
                      image={item.image}
                      loading={loading}
                    />
                    ))
              }
            </OwlCarousel>
          </div>
        </div>
      </div>
      <div className={"contact-section mt-4 mt-md-5 " + (dark ? "dark-theme" : "")}>
        <div className="section_indicator_end  ">
          <div className="container-fluid d-flex align-items-center justify-content-between">
            <span>TRABAJÁ CON NOSOTROS</span>
            <span>SUMATE</span>
          </div>
        </div>
        <div className="contact-content">
          <h2>
            Forma parte de nuestro equipo de profesionales enviándonos tus datos
            y tu CV. Nuestro departamento de RRHH se pondrá en contacto contigo
            a la brevedad.
          </h2>
          {/* <Form/> */}
          <form id="formContact" onSubmit={resetForm}>
            <div className="row form-inputs">
              <input onChange={handleInputChange} type="text" name="fname" required placeholder="Nombre *" />
              <input onChange={handleInputChange} type="text" name="lname" required placeholder="Apellido *" />
              <input onChange={handleInputChange} type="text" name="phone" placeholder="Teléfono" />
              <input onChange={handleInputChange} type="mail" name="email" required placeholder="Mail *" />
              <textarea onChange={handleInputChange} 
                name="message"
                placeholder="Mensaje"
                rows="4"
                style={{ borderBottom: "none" }}
              ></textarea>
            </div>
            <div className="d-flex justify-content-center justify-content-md-end">
              <input type="file"  id="file-upload" name="resume" accept=".doc, .docx, .pdf" required className="d-none" />
              <label for="file-upload" className="brochureBtn">
                <div>
                  <p>ADJUNTAR CURRICULUM VITAE</p> <i className="icon-upload" />
                </div>
              </label>
            </div>
            <hr style={{ border: "solid thin black" }} />
            <div className="d-flex justify-content-end">
              <div className="obligatory">
                * Campos obligatorios
              </div>
            </div>
                <ReCAPTCHA className=" mt-3" sitekey={settings.keys.captcha} onChange={() => setDisabled(false)}
                />
            <button type="submit" className="send-contact link-1 text-uppercase">Enviar</button>
            <div className="position-relative">
              <div className={"alert-contact " + (text === "" ? 'd-none' : 'd-block')}>
                <span>
                  Su mensaje ha sido enviado satisfactoriamente. En breve
                  responderemos su consulta.
                </span>
              </div>
              <div
                className={"caretUp text-dark d-none d-md-block" + (dark ? 'dark-theme' : '')}
                style={{ position: "absolute", right: 0, top: 0, margin: 0 }}
                onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              >
                <p>INICIO</p>
                <div>
                  <i className="icon-arrow"></i>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );


}

function CultureItem({ name, description, icon, isLast }) {
  return (
    <div
      className="culture-item"
      style={{ borderRight: isLast ? "solid 2px black" : "none" }}
    >
      <h5 className="d-flex flex-column align-items-center">
        <i className={icon}></i>
        <span>{name}</span>
      </h5>
      <div>
        <p>{description}</p>
      </div>
    </div>
  );
}
function TrajectoryItem({ loading, image, location, measure, value, id, name }) {


  return loading ? (
    <div className="trajectory-item" key={id}>
      <h2 className="mb-0 mb-md-2">{name}</h2>
      <span className="value">{value}</span>
      <span className="measure">{measure}</span>
      <img src={image} alt="edificio" />
      <span className="location">{location}</span>
    </div>
  ): (<div className="loading-section relative" style={{height:"50vh"}}><div className="lds-dual-ring"></div> </div> );
}
function CounterNumber({ value }) {
  return (
    <VisibilitySensor partialVisibility>
      {({ isVisible }) => {
        return (
          <div>
            {isVisible ? (
              <CountUp
                start={0}
                end={value}
                duration={2.75}
                delay={0}
                separator="."
              >
                {({ countUpRef, start }) => <span ref={countUpRef} />}
              </CountUp>
            ) : (
              <span>0</span>
            )}
          </div>
        );
      }}
    </VisibilitySensor>
  );
}


export default UsMain;
