import React, { useEffect } from "react";
import {ReactSEOMetaTags} from 'react-seo-meta-tags'

import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDevelopmentAction } from "../redux/developmentsDucks";
// -- Componentes -- //
import Main from "../components/emprendimiento/main";
import Oportunity from "../components/emprendimiento/oportunity";
import Details from "../components/emprendimiento/details";
import BluePrints from "../components/emprendimiento/blueprints";
import Location from "../components/emprendimiento/location";
import Files from "../components/emprendimiento/files";
import Contact from "../components/emprendimiento/contact";

import { Helmet } from 'react-helmet-async';


function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

function Emprendimiento() {
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    const getDev = () => {
      dispatch(getDevelopmentAction(id));
    };
    getDev();
  }, []);

  ScrollToTopOnMount();

  return (
    <div className="Emprendimiento ">
      {/* <Helmet prioritizeSeoTags>
        <title>ASPA Patagonia</title>
        <link rel="canonical" href="https://aspatagonia.com.ar/" />
        <meta property="og:description" content="Aspa description"/>
        <meta property="og:image" content="https://mediahaus.com.ar/assets/img/favicon.png" />
      </Helmet> */}
      <Main />
      <Oportunity />
      <Details />
      <BluePrints />
      <Files />
      <Location />
      <div className="Contacto">
        <Contact />
      </div>
    </div>
  );
}

export default Emprendimiento;
