// constantes
const dataInicial = {
  name: "ASPA Desarrollos Patagonia",
  base_url:"/",
  client_id:7,
  social: {
    facebook: "https://www.facebook.com/aspa.desarrollos.patagonia/",
    instagram: "https://www.instagram.com/aspadesarrollos/",
    twitter: "https://twitter.com/aspadesarrollos",
    linkedin: "https://ar.linkedin.com/company/aspa-desarrollos-patagonia",
    youtube: "https://www.youtube.com/channel/UCSrqADrqjRR3g9-anXCViDw",
  },
  contact: {
    phone: "0810-666-6277",
    whatsapp: "1144444444",
    mail: "contacto@aspatagonia.com.ar",
  },
  location: {
    address: "Av. Mengelle 67 / Cipolletti",
    region: "Río Negro / Patagonia / Argentina",
  },
  keys: {
    captcha: "6Ld5nBQdAAAAAA8OPiVYl-LK_8B_g5f-KqMLmqS3",
    // captcha: "6Lc9VaAaAAAAANaHwR9avf7MCpVP7z019S-g_OMi",
    tokko: "259cfca189bdbad9a0fbe437dd0b2f43eae09884",
    mediacore: "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIyIiwianRpIjoiMzJkM2FjY2IxZDVlMGMxZmVjYTBiZGE2NmVhY2M5YzU1MGVlYWFmYTlmM2E5NWI3NGFkM2FlODc0ZDg4NTA4NzZiMTdlMjUzYzQ5YTBmZmEiLCJpYXQiOjE3MDcxNTc5OTQsIm5iZiI6MTcwNzE1Nzk5NCwiZXhwIjoxNzM4NzgwMzk0LCJzdWIiOiIyOSIsInNjb3BlcyI6WyIqIl19.LrHaxTF1gfHB1ViBNdNpxgW5_x5e1JamTjNDCVHwLJ6K78-vgNBdaqdTPI1vlfSmeUEJS6ctRVKsFeRVDem7LzozEu-RGxf5iTi6HiQJX_61NKHyi0qSC1YaNKDNXnc_RPrRczCZcZm4cCQ0s5qHU0zTpkKm8DU0BpPLdnhDJH3CsKqn3pfD_3zA9PHNfisGFemF0qn9bWDMJTTMllREhYb-k_8npd_1L8Sfqk7l1uzotQeZ9cbEuq6zdwV1iUikbj-bf86kGoKkUaqJhZspc0zKeMcg7sfx7a_bZL63wXnqygomCWX28020ThIvQuc2NlSQTMmSBn9vH7DY9fJsXqHV6QLmKy9mjhCD2Lo-9zj-8xtRMj3o-feKG6MRk6qaNxLKT_G_o2KTaQLbNswVkTdR58SZI3OZNjzUWN-0KBpfQMGrEbESmUQEG4uu87VHTqH7CoHmwl-WNE6neDvCNdpke0Lk6KzxVdBdltH7kgEh4NuqwRU-WVK7q3bkfIAm6D0Lb41LcooTFfscP2w9CS-0JbewQjObGhn4F_mH8cZIgkOwCMQ3eUwp7jVXysGY2d6rbhSjhlkSy7WS8U9-FvZwAkjLQ_vxefOHCrNgUHzLS87b-sav10vCV7UfM4POl_WBjbgUq875FXFQ00nsqS-nvO19Jr-mea_Ee9_y374",
    google: "AIzaSyCelKXL-cdJKcw8uWLWDGFrL6tZFASQ5uY",
  },
};
// dd0e6daf8ddc52df5b91f3f57224ac4d752c9db7
// 259cfca189bdbad9a0fbe437dd0b2f43eae09884
export default function settingsReducer(state = dataInicial, action) {
  return state;
}
