import { useDispatch, useSelector } from "react-redux";


const Main = () => {

  const dark = useSelector((store) => store.theme.dark);
  const settings = useSelector((store) => store.settings);

  return (
    <div className={"main " + (dark ? 'dark-theme' : '')}>
      <div className="container-fluid"> 
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <h1 className="text-center text-md-start title-2">LO QUE HACEMOS</h1>
          <p className="text-end d-none d-lg-block">
            Un equipo al servicio <br className="d-none d-lg-block" />
            de nuestros clientes, <br className="d-none d-lg-block" />
            colaboradores <br className="d-none d-lg-block" />y comunidades.
          </p>
        </div>
        <div className="w-100 mt-lg-4 mt-2 img-wrapper ">
          <img
            className="w-100 "
            src={settings.base_url + "assets/images/services.jpg"}
            alt=""
          />
        </div>
        <p className="d-lg-none paragraph-1">
          Un equipo al servicio <br className="d-none d-lg-block" />
          de nuestros clientes, <br className="d-none d-lg-block" />
          colaboradores <br className="d-none d-lg-block" />y comunidades.
        </p>
      </div>
    </div>
  );
};
export default Main;
