import React from "react";
import upArrow from "../assets/svg/UpArrow.svg";
import { useSelector } from "react-redux";


export default function CaretUp({className}) {
  const dark = useSelector((store) => store.theme.dark);
  return (
    <div
      className={"caretUp " + (dark ? ' d-none d-lg-none ' : '')}
      onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
    >
      <p>INICIO</p>
      <img className="upArrow" src={upArrow} />
    </div>
  );
}
