import NoveltyItem from "../../components/NoveltyItem";
import Image1 from "../../assets/images/2.png";
import Image2 from "../../assets/images/building-13.png";
import Image3 from "../../assets/images/building-12.png";
import Image4 from "../../assets/images/building-9.png";
import Image5 from "../../assets/images/building-8.png";
import Image6 from "../../assets/images/building-7.png";
import Image7 from "../../assets/images/building-6.png";
import Image8 from "../../assets/images/building-10.png";
import Image9 from "../../assets/images/building-11.png";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { updateFiltersNovelties,getFiltersNoveltiesAction,clearNoveltyAction } from "../../redux/noveltiesDucks";
import OwlCarousel from "react-owl-carousel2";



const Main = () => {

  const dispatch = useDispatch();

  const dark = useSelector((store) => store.theme.dark);
  const novelties = useSelector((store) => store.novelties.novelties).data;
  const {data} = useSelector((store) => store.novelties.grid_novelties);
  const tags = useSelector((store) => store.novelties.tags);
  const currentFilters = useSelector((store) => store.novelties.filtersNovelties);

  // loading data from redux
  const loading_grid = useSelector((store) => store.novelties.loadingGrid);

  const [activeTags,setActiveTags] = useState([])

  // Get last featured
  const getFeatured = () => {
    // filter featured
    const list_featured = novelties.filter(element => element.featured === true || element.featured.toString() === "1");
    // return sorted novelties
    return sortNoveltyByPosition(list_featured);
  }

  // Toggle tags filters
  const toggleFilter = (filter) => {
    if(activeTags.find(element => element.id === filter.id)){
      return setActiveTags(activeTags.filter(element => element.id !== filter.id));
    }
    return setActiveTags([...activeTags, filter]);
  }

  // Update filters in redux
  useEffect(() => {
    // if(activeTags.length > 0){
      dispatch(updateFiltersNovelties(activeTags));
    // }
  }, [activeTags])

  useEffect(( ) => {
    dispatch(updateFiltersNovelties([]));
    dispatch(clearNoveltyAction());
  } ,[])

  // If filters tag redux change, dispatch updateFiltersNovelties (DISABLED, ONLY WITH BUTTON SEACH)
  // useEffect(() => {
  //     console.log(currentFilters)
  //     dispatch(getFiltersNoveltiesAction());
  // }, [currentFilters])

  
  const options = {
    items: 1,
    nav: false,
    rewind: true,
    dots: false,
    autoplay: false,
    center: true,
    stagePadding: window.innerWidth * 0.1,
    margin: window.innerWidth * 0.035,
    responsive: {
      768: {
        items: 2,
        // stagePadding: window.innerWidth * 0.1,
      },
    },
  };

  // Sort by highest position 
  const sortNoveltyByPosition = (noveltyData) => noveltyData.sort((n1, n2) => n2.position - n1.position)

  return (
    <div className={"main " + (dark ? 'dark-theme' : '')}>
      <div className="container-fluid">
        <h1 className="title-1 text-center text-md-start">Novedades <div className="d-inline-block d-lg-none">destacadas</div> </h1>
        <div className="d-block d-lg-none"> {/* Responsive carousel  */}
          <OwlCarousel options={options}>
            {
              novelties ? 
              getFeatured() ? (
                getFeatured().map((element, index) => (
                <NoveltyItem
                  key={index}
                  image={"https://dev.mediacore.com.ar" + (element.image)} 
                  isOutstanding={true}
                  title={element.title}
                  description={element.excerpt}
                  id={element.id}
                  slug={element.slug}
                  featured={true}
                  />
                )
              ))
              :
              'No hay novedades destacadas.'
              : ''
            }
          </OwlCarousel>
        </div>
        <div className="filters mt-2 mt-md-4"> {/* Filtros  */}
          <div className="filter-search">
            <div>
              <i className="icon-label" ></i>
              <div className="labels">
                {activeTags.map(tag => (
                  <button className="label animate__animated animate__fadeInRight">
                    {tag.name} <i onClick={() => toggleFilter(tag)} className="icon-cross"></i>
                  </button>
                ))}
              </div>
            </div>
            <button onClick={() => dispatch(getFiltersNoveltiesAction(activeTags))} className="search">
              <i className="icon-search"></i>
            </button>
          </div>
          <div className="d-flex flex-wrap gap-1 gap-md-3 my-1 my-md-4">
            {tags.map(tag => (
              <button onClick={() => toggleFilter(tag)}className={"animate__animated animate__fadeInRight label " + (activeTags.find(element => element.id === tag.id) ? 'd-none' : '')}>{tag.name}</button>
              ))}
          </div>
        </div>
        <div className="subtitle-notes row mt-2 mt-md-5">
            <div className="col-md-6 d-none d-md-flex justify-content-start align-items-center">
              <h2>TODAS LAS NOVEDADES</h2>
              {!loading_grid && !data ? (<div className="loading-section justify-content-start ms-5" style={{height:"50px",width:"80px"}}><div className="lds-dual-ring"></div> </div> ) : null}
            </div>
            <div className="col-md-6 d-none d-md-flex justify-content-start align-items-center">
              <h2>NOVEDADES DESTACADAS</h2>
              {!novelties ? (<div className="loading-section justify-content-start ms-5" style={{height:"50px",width:"80px"}}><div className="lds-dual-ring"></div> </div> ) : null}
            </div>
        </div>
        <div className="mosaic-grid"> {/* Mosaico de novedades  */}
        <div className=" all-novelties order-2 order-lg-1">
            <div className="novelties-grid">
              {
                !loading_grid ? 
                  data && !loading_grid ?
                  data.length > 0 ?
                  sortNoveltyByPosition(data).map((element, index) => (
                    <>
                    {
                      element.active.toString() === "1" || element.active.toString() === 'true' ?
                        <NoveltyItem
                          key={index}
                          image={"https://dev.mediacore.com.ar" + (element.image ? element.image : '')}
                          title={element.title}
                          description={element.excerpt}
                          id={element.id}
                          slug={element.slug}
                          />              
                        :''
                    }
                    </>
                  ))
                :'No hay novedades para tu busqueda.'
                :''
                :''
              }
            </div>
        </div>
        <div className="order-lg-2 order-1 d-none d-lg-block">
          <div className="outstanding-novelty">
            <div className="d-block d-md-none ">
              <h2 className="title-3">NOVEDADES DESTACADAS</h2>
            </div>
              {
              novelties ? 
              getFeatured() ? (
                getFeatured().map((element, index) => (
                  element.active.toString() === "1" || element.active.toString() === 'true' ?
                  <NoveltyItem
                    key={index}
                    image={"https://dev.mediacore.com.ar" + (element.image)} 
                    isOutstanding={true}
                    title={element.title}
                    description={element.excerpt}
                    id={element.id}
                    slug={element.slug}
                    />
                    :''
                  )
                ))
              :
              'No hay novedades destacadas.'
              : ''
              }
          </div>
        </div>
        </div>
      </div>
    </div>
  )
};
export default Main;
