import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


const Services = () => {
  
  const settings = useSelector((store) => store.settings);

  return (
    <div className="services">
      <div className="section_indicator">
        <div className="container-fluid  d-flex align-items-center justify-content-between">
          <span>QUÉ HACEMOS</span>
          <span>NUESTROS SERVICIOS</span>
        </div>
      </div>
      <div className="container-fluid">
        <div className="serviceText">
          <h2 className="title-3">
            un equipo QUE Desarrolla desde el diseño de proyecto y la ejecución
            de obras, hasta la venta en sus tres etapas (producción,
            comercialización y posventa).
            <br className="d-none d-lg-inline-block" />{" "}
            <br className="d-none d-lg-inline-block" />
          </h2>
          <h3 className="title-3">
            como desarrollistas nuestra labor está signada por la
            responsabilidad, el compromiso y la prudencia procurando lograr la
            mayor eficiencia.
          </h3>
        </div>
        <div className="row">
          <div className="col-lg-10">
            <div className="row justify-content-center justify-content-md-between">
              <div className="col-lg-3  d-flex align-items-center align-items-md-start flex-column text-center text-md-start">
                <i className="icon-book preassets"></i>
                <h5>01. EDIFICIOS y/o viviendas.</h5>
                <p className="title-3">
                  Estructuración e implementación de proyectos inmobiliarios.
                </p>
              </div>

              <div className="col-lg-3  d-flex align-items-center align-items-md-start flex-column text-center text-md-start">
                <i className="icon-development preassets"></i>
                <h5>02. Proyectos Especiales.</h5>
                <p className="title-3">Diseño, implantación y start-ups de proyectos.</p>
              </div>

              <div className="col-lg-3  d-flex align-items-center align-items-md-start flex-column text-center text-md-start">
                <i className="icon-urban preassets"></i>
                <h5>03. Urbanizaciones.</h5>
                <p className="title-3">Estudio de códigos urbanos.</p>
              </div>
            </div>
          </div>
          <div>
            <br className="d-none d-md-block" />
            <br />
            <div>
              <div className="text-center text-md-start">
                <Link className="link-1-dark" to={settings.base_url + "servicios"}>¡VER TODO!</Link>
              </div>
            </div>
            <br className="d-none d-md-block" />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
