import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Campos from "../form";
import ReCAPTCHA from "react-google-recaptcha";

import { formTokko } from "../../redux/contactDucks";
import { cleanFields } from "../../redux/contactDucks";
import Form from "../contacto/form";
function Contact() {
  const { data } = useSelector((store) => store.developments.development);
  const dark = useSelector((store) => store.theme.dark);
  const settings = useSelector((store) => store.settings);
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [disabled, setDisabled] = useState(true);
  const resetForm = (event) => {
    event.preventDefault();
    dispatch(formTokko("Emprendimiento",data.name, data.id));
    dispatch(cleanFields());
    document.getElementById("formContact").reset();
    setText(
      "Su mensaje ha sido enviado satisfactoriamente. En breve responderemos su consulta."
    );
  };

  return data ? (
    <div className={"form contact " + (dark ? "dark-theme" : '')}>
      <div className="section_indicator">
        <div className="container-fluid  d-flex align-items-center justify-content-between pb-2 pb-md-0">
          <span>{data.publication_title}</span>
          <span>¿QUERÉS SABER MÁS?</span>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row d-flex align-items-start">
          <div className="col-lg-6 order-1 order-lg-1">
            <h2 className="title-3-white" style={{fontWeight:300}}>¡Dejanos tu consulta!</h2>
          </div>
          <div className="col-lg-6 d-flex justify-content-lg-end justify-content-center order-lg-2 order-3 mt-lg-0 mt-4">
            <div className="contact-items">
              <a href="mailto:">
                {settings.contact.mail} <br />
              </a>
              <a href="#">{settings.contact.phone} </a>
              <p>
                {settings.location.address} <br />
                {settings.location.region}{" "}
              </p>
            </div>
          </div>
          <div className="col-12 order-lg-3 order-2 ">
            <form onSubmit={resetForm} id="formContact">
              <Campos />
              <div className="d-flex d-lg-none justify-content-center">
                <ReCAPTCHA
                  className=" mt-3"
                  sitekey={settings.keys.captcha}
                  onChange={() => setDisabled(false)}
                />
              </div>
              <div className="d-flex d-lg-block align-items-center justify-content-between mt-2">
                <div className="d-lg-flex d-block justify-content-between align-items-center">
                  {/* <div className="g-recaptcha d-none d-lg-block " data-sitekey= data-callback="enableBtn"></div> */}
                  <ReCAPTCHA
                    className="d-none d-lg-block"
                    sitekey={settings.keys.captcha}
                    onChange={() => setDisabled(false)}
                  />
                  <div className="social-media mt-lg-auto d-flex justify-content-start justify-content-lg-end ">
                    <a target="_blank" className="single" href={settings.social.facebook}>
                      <i className="icon-facebook"></i>
                    </a>
                    <a target="_blank" className="single" href={settings.social.instagram}>
                      <i className="icon-instagram"></i>
                    </a>
                    <a target="_blank" className="single" href={settings.social.linkedin}>
                      <i className="icon-linkedin"></i>
                    </a>
                    <a target="_blank" className="single" href={settings.social.twitter}>
                      <i className="icon-twitter"></i>
                    </a>
                    <a target="_blank" className="single" href={settings.social.youtube}>
                      <i className="icon-youtube"></i>
                    </a>
                  </div>
                </div>
                <button
                  type="submit"
                  style={{ border: "none", backgroundColor: "transparent" }}
                  className="mt-1"
                  disabled={disabled}
                >
                  <span className="link-1">ENVIAR</span>
                </button>
              </div>
              <div>
                <span
                  id="text-form"
                  className={(text == "" ? "d-none" : " mt-4 d-inline-block")}
                >
                  {text}
                </span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
}

export default Contact;
