import React , {useEffect} from 'react'
import Main from '../components/servicios/main'
import Services from '../components/servicios/services'

// -- Componentes -- //


function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}


function Servicios(){

    ScrollToTopOnMount()
    return(
        <div className="Servicios">
          <Main></Main>
          <Services></Services>
        </div>
    )
}

export default Servicios
