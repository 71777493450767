import React from "react";
import { useSelector } from "react-redux";

function Hero() {
  const { data } = useSelector((store) => store.developments.development);

  const dark = useSelector((store) => store.theme.dark);

  return (
    <div className={"hero " +( dark ? "dark-theme" : "")}>
      <div className="container-fluid">
        <h1 className="title-1 text-center text-md-start">Vos elegís, nosotros te acompañamos</h1>
      </div>
    </div>
  );
  // : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default Hero;
