import React , {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import { getDevelopmentsAction } from '../redux/developmentsDucks';
import { getFiltersDevelopmentsAction } from '../redux/developmentsDucks';


// -- Componentes -- //
import Hero from '../components/emprendimientos/hero-slider'
import Grid from '../components/emprendimientos/grid-developments'

function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}


function Emprendimientos(){

    const dispatch = useDispatch();

    useEffect(() => {
        const getDevs = () => {
            dispatch(getDevelopmentsAction())
            dispatch(getFiltersDevelopmentsAction())
        }
        getDevs()
    }, [])

    ScrollToTopOnMount()
    return(
        <div className="emprendimientos pt-lg-5 mt-lg-5">
            <Hero />
            <Grid />
        </div>
    )
}

export default Emprendimientos
