import Fade from "react-reveal/Fade";
import { useDispatch, useSelector } from "react-redux";


const Services = () => {

  const dark = useSelector((store) => store.theme.dark);

  return (
    <div className={"services pt-lg-4 " + (dark ? 'dark-theme' : '') }>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 mt-2 mt-md-4 mb-lg-5">
            <div className="section_indicator d-flex align-items-center justify-content-between">
              <span>QUÉ HACEMOS</span>
              <span>NUESTROS SERVICIOS</span>
            </div>
          </div>
          <div className="col-12 mt-lg-5">
            <Fade left>
              <div className="content-services d-lg-flex align-items-start justify-content-between mt-1">
                <div className="content-img d-inline-flex align-items-center justify-content-center ">
                  <i className="icon-book"></i>
                </div>

                <div className="content ms-0 ms-lg-2 mb-4 mb-md-5 ">
                  <span className="d-block mt-1">
                    01. EDIFICIOS y/o viviendas.
                  </span>
                  <h2 className="title-4">
                    Estructuración e implementación <br /> de proyectos
                    inmobiliarios.
                  </h2>
                  <p className="paragraph-1 my-1">
                    Proyecto, diseño y cálculo integral. Dirección técnica de
                    obra. Control presupuestario y auditoría de costos.
                    Construcción llave en mano. Asesoramiento legal, contable e
                    impositivo. Estructuración e implementación de fideicomisos
                    inmobiliarios.
                  </p>
                </div>
              </div>
            </Fade>
            <Fade right>
              <div className="content-services d-lg-flex align-items-start justify-content-between mt-1">
                <div className="content-img d-inline-flex align-items-center justify-content-center">
                  <i className="icon-development"></i>
                </div>

                <div className="content ms-0 mb-4 mb-md-5 ">
                  <span className="d-block mt-1">
                    02. Proyectos Especiales.
                  </span>
                  <h2 className="title-4">
                    Diseño, implantación <br /> y start-ups de proyectos.
                  </h2>
                  <p className="paragraph-1 my-1">
                    Turístico inmobiliarios y real estate hotelero. Centros
                    comerciales. Complejos recreativos. Edificios de oficinas.
                    Espacios corporativos. Desarrollos turísticos hoteleros, de
                    descanso, temáticos y de 2da residencia. Complejos
                    vacacionales & condo-hoteles. Edificios de oficinas y
                    corporativos. Complejos mixtos residento-comerciales.
                  </p>
                </div>
              </div>
            </Fade>
            <Fade left>
              <div className="content-services d-lg-flex align-items-start justify-content-between mt-3">
                <div className="content-img d-inline-flex align-items-center justify-content-center">
                  <i className="icon-urban"></i>
                </div>

                <div className="content ms-0 mb-4 mb-md-5 ">
                  <span className="d-block mt-1">03. Urbanizaciones.</span>
                  <h2 className="title-4">Estudio de códigos urbanos.</h2>
                  <p className="paragraph-1 my-1">
                    Integración de proyectos de loteos residenciales y barrios
                    cerrados. Estructuración jurídica. Política de precios y
                    definición de canal de ventas. Contratación y administración
                    de obras de infraestructura. Esquemas asociativos entre
                    propietarios de la tierra e inversionistas.
                  </p>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Services;
