import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import OwlCarousel from "react-owl-carousel2";

function Hero() {
  const options = {
    items: 1.1,
    nav: true,
    rewind: true,
    dots: false,
    autoplay: false,
    center: true,
    stagePadding: 0,
    margin: window.innerWidth * 0.1,
    navText: [
      "<div class='navButtonLeft'></div>",
      "<div class='navButtonRight'></div>",
    ],
    responsive: {
      992: {
        center: true,
        items: 1.95,
        margin: window.innerWidth * 0.15,
      },
    },
  };

  // STORE
  const dark = useSelector((store) => store.theme.dark);
  const stateCodes = useSelector((store) => store.developments.stateCodes);
  const { data } = useSelector((store) => store.developments.developments);

  function makeShortLocation(text) {
    const spliter = text.split("|");
    return spliter[1] + " | " + spliter[0];
  }

  const normalizeText = (text) => {
    return text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g,"-")
  }


  function getPortada(array) {
    // for (let index = 0; index < array.length; index++) {
    //   // if (array[index].is_front_cover) {
    //   //   return array[index];
    //   // }
    // }
    return array[1] ? array[1] : '';
  }

  const car = useRef(null);
  return data ? (
    <div className={("hero-slider " + (dark ? "dark" : ""))}>
      <OwlCarousel options={options} ref={car}>
        {data.objects.map((item, index) => {
          return (
            item.is_starred_on_web ?
            <div key={index} className="content-item">
              <Link to={"desarrollos/" + item.id + "/" + normalizeText(item.name)}>
                <div className="content-img">
                  <div
                    style={{
                      backgroundImage:
                        "url(" +
                        (item.photos[0]
                          ? getPortada(item.photos).original
                          : "https://www.trueking.org/static/media/no-product-image.31b3d017.png") +
                        ")",
                    }}
                    className="img"
                  ></div>
                  <div className="hover-filter"></div>
                  <div className="text d-lg-flex justify-content-between">
                    <h2>{item.name}</h2>
                    <h5 className="mt-auto">
                      ESTADO:{" "}
                      <span className="text-uppercase">
                        {" "}
                        {stateCodes[item.construction_status].name}
                      </span>{" "}
                      <br /> <span className="mt-1 d-block d-lg-none"></span>{" "}
                      {makeShortLocation(item.location.short_location)}
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            :''
          );
        })}
      </OwlCarousel>
    </div>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
}
export default Hero;
