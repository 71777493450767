import axios from 'axios'

// constantes
const dataInicial = {
    developments: [],
    grid_developments: [],
    development:{},
    favorites:[],
    filtersDevelopment:{
        type:'',
        location:'',
        status:''
    },
    stateCodes : [
        {},
        {
            id:1,
            name:"Comercializando"
        },
        {},
        {
            id:3,
            name:"En pozo"
        },
        { 
            id:4,
            name:"En Construcción"
        },
        {},
        {
            id:6,
            name:"Construcción Terminada"
        }
    ],
}
const LOADING = "LOADING";
const GET_DEVELOPMENT = 'GET_DEVELOPMENT'
const GET_DEVELOPMENTS = 'GET_DEVELOPMENTS'
const GET_GRID_DEVELOPMENTS = 'GET_GRID_DEVELOPMENTS'
const CLEAN_DEVELOPMENT = 'CLEAN_DEVELOPMENT'
const CLEAN_GRID_DEVELOPMENT = 'CLEAN_GRID_DEVELOPMENT'
const UPDATE_FILTERS = 'UPDATE_FILTERS'
const GET_FAV_PROPERTY = "GET_FAV_PROPERTY";
const CLEAR_FAV_PROPERTY = "CLEAR_FAV_PROPERTY";
const DELETE_FAV_PROPERTY = "DELETE_FAV_PROPERTY";

// reducer
export default function developmentReducer(state = dataInicial, action){
    switch(action.type){
        case GET_DEVELOPMENT:
            return {...state, development : action.payload }
        case GET_DEVELOPMENTS:
            return {...state, developments : action.payload }
        case GET_GRID_DEVELOPMENTS:
            return {...state, grid_developments : action.payload }
        case CLEAN_DEVELOPMENT:
            return {...state, development : action.payload }
        case CLEAN_GRID_DEVELOPMENT:
            return {...state, grid_developments : action.payload }
        case UPDATE_FILTERS:
            return {...state, filtersDevelopment : action.payload }
        case GET_FAV_PROPERTY:
            return { ...state, favorites: [...state.favorites, action.payload], loading:false};
        case DELETE_FAV_PROPERTY:
            return { ...state, favorites: [...state.favorites, action.payload], loading:false};
        case CLEAR_FAV_PROPERTY:
            return { ...state, favorites: [] , loading:false};
        default:
            return state
    }
}

// actions
export const getDevelopmentAction = (id) => async (dispatch, getState) => {
    try {
        const res = await axios.get('https://www.tokkobroker.com/api/v1/development/' + id + '/?lang=es_ar&format=json&key=' + getState().settings.keys.tokko)
        console.log(res)
        dispatch({
            type: GET_DEVELOPMENT,
            payload: res
        })
    } catch (error) {
        console.log(error)
    }
}
export const getDevelopmentsAction = () => async (dispatch, getState) => {
    try {
        const res = await axios.get('https://api-mh.mediahaus.com.ar/api/v1/tokko/developments/search?cache=false',{
            headers:{
                'X-TOKKO-KEY': getState().settings.keys.tokko
            },
        })
        dispatch({
            type: GET_DEVELOPMENTS,
            payload: res
        })
    } catch (error) {
        console.log(error)
    }
}
export const getFiltersDevelopmentsAction = () => async (dispatch, getState) => {
    var type = ''
    var location = ''
    var status = ''
    if(getState().developments.filtersDevelopment.type){
        type = '&tipo=' + getState().developments.filtersDevelopment.type
    }
    if(getState().developments.filtersDevelopment.location){
        location = '&location=' + getState().developments.filtersDevelopment.location
    }
    if(getState().developments.filtersDevelopment.status){
        status = '&construction_status=' + getState().developments.filtersDevelopment.status
    }
    try {
        const res = await axios.get('https://api-mh.mediahaus.com.ar/api/v1/tokko/developments/search?cache=false' + type + location + status ,{
            headers:{
                'X-TOKKO-KEY': getState().settings.keys.tokko
            },
        })
        dispatch({
            type: GET_GRID_DEVELOPMENTS,
            payload: res
        })
    } catch (error) {
        console.log(error)
    }
}
export const cleanDevelopmentAction = (id) => (dispatch, getState) => {
    switch (id){
        // ID 0 == DEVELOPMENT //////// ID 1 == GRID_DEVELOPMENTS // ALL
        case 0:{
            dispatch({
                type: CLEAN_DEVELOPMENT,
                payload: {}
            })
            break;
        }
        case 1:{
            dispatch({
                type: CLEAN_GRID_DEVELOPMENT,
                payload: {}
            })
            break;
            
        }
        case 2:{
            dispatch({
                type: CLEAN_DEVELOPMENT,
                payload: {}
            })
            dispatch({
                type: CLEAN_GRID_DEVELOPMENT,
                payload: {}
            })
            dispatch({
                type: UPDATE_FILTERS,
                payload: {status:'',type:'',location:''}
            })
            break;
        }
    }

}
export const updateFiltersDevelopment = (type,location,status) => (dispatch, getState) => {
    const res = {
        type,
        location,
        status
    }
    dispatch({
        type: UPDATE_FILTERS,
        payload: res
    })
}
export const getFavoritesAction = (id) => async (dispatch, getState) => {

    dispatch({
      type: LOADING,
  })
    if(getState().developments.favorites.find(element => element.data.id.toString() === id.toString())){
      const new_favs = getState().developments.favorites.filter(element => element.data.id.toString() !== id.toString())
      dispatch({
        type: DELETE_FAV_PROPERTY,
        payload: new_favs
    })
    }
    try {
        const res = await axios.get(' https://api-mh.mediahaus.com.ar/api/v1/tokko/developments/' + id +'?cache=true' ,{
            headers:{
                'X-TOKKO-KEY': getState().settings.keys.tokko
            },
        })
        dispatch({
            type: GET_FAV_PROPERTY,
            payload: res
        })
    } catch (error) {
        console.log(error)
    }
  
}
export const clearFavoritesAction = (id) => async (dispatch, getState) => {
dispatch({
    type: CLEAR_FAV_PROPERTY,
})
}
export const clearPropertiesAction = (id) => async (dispatch, getState) => {
dispatch({
    type: CLEAR_PROPERTIES,
})
}