import React, {useEffect} from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps"
import {useSelector} from 'react-redux'
import mapStyles from './mapStyles.json';
import Market from '../assets/images/marker.png'
const defaultMapOptions = {
  styles: mapStyles
};

function changeFormat(value){
  var convert = value.toString()
  return convert.replace(',','.')
}

export default function getMap() {
  const {data} = useSelector(store => store.developments.development)
  const settings = useSelector(store => store.settings)
  
  const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
    defaultOptions={defaultMapOptions}
    defaultZoom={16}
    defaultCenter={{ lat: (props.latitud), lng: (props.longitud)}}
  >
    {props.isMarkerShown && <Marker icon={Market} position={{ lat: (props.latitud), lng: (props.longitud) }} />}
  </GoogleMap>
  

))

  return data ?(
    <div className="map">
      <MyMapComponent
        isMarkerShown
        latitud={parseFloat(changeFormat(data.geo_lat))}
        longitud={parseFloat(changeFormat(data.geo_long))} 
        googleMapURL={'https://maps.googleapis.com/maps/api/js?key='+ settings.keys.google}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        />
    </div>


  ):''

}