import React from 'react'
import {useSelector} from 'react-redux'
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';



function BluePrints (){

    const settings = useSelector((store) => store.settings);
  
    const options = {
        settings: {
        	autoplaySpeed: 1500,
        	transitionSpeed: 900
        },
        buttons: {
        	iconColor: '#ffff'
        },
        caption: {
        	captionColor: '#fff',
        	captionFontFamily: 'Raleway, sans-serif',
        	captionFontWeight: '300',
        	captionTextTransform: 'uppercase'
        },
        progressBar: {
        	height: '20px',
        	fillColor: '#191919',
        	backgroundColor: 'white'
        }
    };
    
    function checkBluePrints(data){
        for (let index = 0; index < data.photos.length; index++) {
            const element = data.photos[index];
            if(element.is_blueprint){
                return true
            }
        }
        return false
    }

    const dark = useSelector((store) => store.theme.dark);
    const {data} = useSelector(store => store.developments.development)
    return data ? (
        <div className={"blue_prints " + (dark ? 'dark-theme' : '')}>
            {checkBluePrints(data) ? (
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="section_indicator d-flex align-items-center justify-content-between">
                                        <span>{data.publication_title}</span>
                                        <span>PLANOS  </span>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <h2 style={{fontWeight:300}} className="title-3-white text-uppercase">
                                        TIPOLOGÍAS
                                    </h2>
                                </div>
                                    <div className="col-lg-8">
                                        <SimpleReactLightbox>
                                            <SRLWrapper options={options}>
                                                <div className="row justify-content-between">

                                                    {data.photos.map((item) => (
                                                            item.is_blueprint ? (
                                                                <div key={item.original} className="col-lg-5">
                                                                    <a className="content-img" href={item.original} caption="">
                                                                        <img className="img-bluepints" src={item.original} alt={item.description}/> 
                                                                        <img src={settings.base_url + "assets/images/expand.svg"} className="expand" alt=""/>
                                                                    </a>
                                                                    <h5 className="text-end mt-3">{item.description}</h5>
                                                                </div>
                                                                )
                                                            : null
                                                        ))}
                                                    
                                                </div>
                                            </SRLWrapper>
                                        </SimpleReactLightbox>
            
                                        <div className="border-blueprints"></div>
                                    </div>
                                
                              
                            </div>
                        </div>
            ) : ''}

        </div>
    ) : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default BluePrints
