import React , {useEffect} from 'react'
import Main from '../components/contacto/main'
import Form from '../components/contacto/form'

// -- Componentes -- //


function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}


function Contacto(){

    ScrollToTopOnMount()
    return(
        <div className="Contacto ">
          <Main></Main>
          <Form></Form>
        </div>
    )
}

export default Contacto
