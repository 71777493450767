import OwlCarousel from "react-owl-carousel2";
import ScrollToTop from "../../components/funcScrollToTop";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {getNoveltiesAction} from '../../redux/noveltiesDucks'
import NoveltyItem from "../NoveltyItem";
import CaretUp from "../../components/CaretUp";
import Image1 from "../../assets/images/1.png";
import Image2 from "../../assets/images/2.png";
import Image3 from "../../assets/images/3.png";
import { useEffect } from "react";

const Novelties = () => {
  const settings = useSelector((store) => store.settings);
  const {data} = useSelector((store) => store.novelties.novelties);

  const options = {
    items: 1,
    nav: false,
    rewind: true,
    dots: false,
    autoplay: false,
    center: false,
    stagePadding: window.innerWidth * 0.1,
    margin: window.innerWidth * 0.035,
    responsive: {
      768: {
        items: 2,
        // stagePadding: window.innerWidth * 0.1,
      },
    },
  };

  const dispatch = useDispatch();

  useEffect(() =>{
    dispatch(getNoveltiesAction())
  },[])

  const getActive = (data,count) => {
    return sortNoveltyByPosition(data)
            .filter(element => (element.active === true || element.active.toString() === "1"))
            .slice(0,count);
  }

  // Sort by highest position 
  const sortNoveltyByPosition = (noveltyData) => noveltyData.sort((n1, n2) => n2.position - n1.position)

  return (
    <div className="novelties home">
      <div className="section_indicator">
        <div className="container-fluid  d-flex align-items-center justify-content-between">
          <span>NOVEDADES</span>
          <span className="text-center">DESTACADAS</span>
          <span className="text-end">2021</span>
        </div>
      </div>
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-end">
          <h2 className="d-inline-block">Últimas novedades</h2>
          <Link
            to={`${settings.base_url}novedades`}
            className="d-inline-flex align-items-center link-1-dark"
            href="#"
          >
            VER <span>+</span>
          </Link>
        </div>
        <OwlCarousel options={options}>
          {data ?
            getActive(data,8).map((noveltie, index) => (
                <div className="col-lg-4">
                  <NoveltyItem
                    key={index}
                    image={"https://dev.mediacore.com.ar" + noveltie.image}
                    title={noveltie.title}
                    description={noveltie.excerpt}
                    id={noveltie.id}
                    slug={noveltie.slug}
                    home={true}
                  />
                </div>
            ))  
            :''
        }
  
        </OwlCarousel>
        <div className="row mt-3 justify-content-between d-none d-lg-flex">
        {data ?
            getActive(data,3).map((noveltie, index) => (
                <div className="col-lg-4">
                  <NoveltyItem
                    key={index}
                    image={"https://dev.mediacore.com.ar" + noveltie.image}
                    title={noveltie.title}
                    description={noveltie.excerpt}
                    id={noveltie.id}
                    slug={noveltie.slug}
                    home={true}
                  />
                </div>
            ))  
            :''
        }
        </div>
        <CaretUp className="mt-3 mt-md-5 text-center text-md-end" />
      </div>
    </div>
  );
};

export default Novelties;
