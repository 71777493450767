import React from "react";
import { useSelector } from "react-redux";

function Files() {
  const { data } = useSelector((store) => store.developments.development);
  const dark = useSelector((store) => store.theme.dark);

  function spliter(file) {
    const array = file.split("/");

    return array[array.length - 1].split(".")[0];
  }
  return data ? (
    data.files[0] ? (
      <div className={"files " + (dark ? 'dark-theme' : '')}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <h2
                style={{ fontWeight: 300 }}
                className="title-3-white text-uppercase"
              >
                ZONA DE <br className="d-none d-lg-block" /> DESCARGA
              </h2>
            </div>
            <div className="col-lg-8">
              <div className="row">
                {data.files.map((item) => (
                  <div
                    key={item.file}
                    className="col-lg-4 col-12 mb-3 pe-0 pe-lg-2"
                  >
                    <a
                      className="text-uppercase"
                      target="_blank"
                      href={item.file}
                      download
                    >
                      <i className="icon-upload me-lg-3 me-1 "></i>{" "}
                      {spliter(item.file)}
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      ""
    )
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
}

export default Files;
