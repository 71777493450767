import React , {useEffect} from 'react'
import Main from '../components/novelties/main'
import { useSelector, useDispatch } from "react-redux";
import {getNoveltiesAction,getFiltersNoveltiesAction,getTagsAction} from '../redux/noveltiesDucks'


function Novedades(){
  const dispatch = useDispatch();
  
  useEffect(() =>{
    dispatch(getNoveltiesAction())
    dispatch(getFiltersNoveltiesAction())
    dispatch(getTagsAction())
  },[])
    return(
        <div className="Novedades">
          <Main/>
        </div>
    )
}

export default Novedades
