import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import Campos from "../form";

import { formTokko } from "../../redux/contactDucks";
import { cleanFields } from "../../redux/contactDucks";

function Form() {
  const dispatch = useDispatch();
  const settings = useSelector((store) => store.settings);
  const [text, setText] = useState("");
  const [disabled, setDisabled] = useState(true);
  const dark = useSelector((store) => store.theme.dark);

  const captchaCheck = () => {};
  const resetForm = (event) => {
    event.preventDefault();
    dispatch(formTokko("Consulta", null));
    document.getElementById("formContact").reset();
    dispatch(cleanFields());
    setText(
      "Su mensaje ha sido enviado satisfactoriamente. En breve responderemos su consulta."
    );
  };
  return (
    <div className={"form py-lg-5 py-3 " + (dark ? 'dark-theme' : '')}>
      <div className="container-fluid">
        <div className="row">
          <h4
            className="mt-3 mb-3 mb-lg-5 title-3-white "
            style={{ fontWeight: 300 }}
          >
            ¡Dejanos tu mensaje!
          </h4>
          <form onSubmit={resetForm} className="contact" id="formContact">
            <Campos />
            <div className="d-flex d-lg-none justify-content-center">
              <ReCAPTCHA
                className=" mt-3"
                sitekey={settings.keys.captcha}
                onChange={captchaCheck}
              />
            </div>
            <div className="d-flex d-lg-block align-items-center justify-content-between mt-2">
              <div className="d-lg-flex d-block justify-content-between align-items-center">
                {/* <div className="g-recaptcha d-none d-lg-block " data-sitekey= data-callback="enableBtn"></div> */}
                <ReCAPTCHA
                  className="d-none d-lg-block"
                  sitekey={settings.keys.captcha}
                  onChange={() => setDisabled(false)}
                />
              <div className="d-block d-lg-none justify-content-start align-items-center">
                <button
                  type="submit"
                  style={{ border: "none", backgroundColor: "transparent" }}
                  className="d-block d-lg-none mb-2"
                  disabled={disabled}
                >
                  <span className="link-1">ENVIAR</span>
                </button>
                  <span
                    id="text-form"
                    className={(text == "" ? "d-none" : "d-lg-none") + ""}
                  >
                    {text}
                  </span>
              </div>
                <div className="social-media mt-lg-auto d-flex justify-content-start justify-content-lg-end ">
                  <a target="_blank" className="single" href={settings.social.facebook}>
                    <i className="icon-facebook"></i>
                  </a>
                  <a target="_blank" className="single" href={settings.social.instagram}>
                    <i className="icon-instagram"></i>
                  </a>
                  <a target="_blank" className="single" href={settings.social.linkedin}>
                    <i className="icon-linkedin"></i>
                  </a>
                  <a target="_blank" className="single" href={settings.social.twitter}>
                    <i className="icon-twitter"></i>
                  </a>
                  <a target="_blank" className="single" href={settings.social.youtube}>
                    <i className="icon-youtube"></i>
                  </a>
                </div>
              </div>
              <div className="d-lg-flex d-none justify-content-start align-items-center">
                <button
                  type="submit"
                  style={{ border: "none", backgroundColor: "transparent" }}
                  className="d-none d-lg-block me-4"
                  disabled={disabled}
                >
                  <span className="link-1">ENVIAR</span>
                </button>
                  <span
                    id="text-form"
                    className={(text == "" ? "d-none" : "d-lg-inline-block d-none") + ""}
                  >
                    {text}
                  </span>
              </div>

            </div>
            
          </form>
        </div>
      </div>
    </div>
  );
}

export default Form;
