import { Link, useLocation } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import React , { useState, useEffect} from 'react'
import { updateFields } from '../redux/contactDucks'
import { formTokko } from "../redux/contactDucks";


function Footer() {
  const dark = useSelector((store) => store.theme.dark);
  const settings = useSelector((store) => store.settings);
  let location = useLocation().pathname.split("/");
  let curLocation = location[location.length - 1];
  const [text, setText] = useState("");

  const [disabled,setDisabled] = useState(true)

  const dispatch = useDispatch();
  const [datos, setDatos] = useState({
      nombre:'',
      apellido:'',
      cellphone:'',
      phone:'',
      email:'',
      work_name:'',
      text:'',
      properties:'',
      tags:''
  })
  const handleInputChange = (event) => {
      setDatos({
          ...datos,
          [event.target.name] : event.target.value, 
      })
  }
  useEffect(() => {
      const update = () => {
          dispatch(updateFields(datos))
      }
      update()
  }, [datos])

  const resetForm = (event) => {
    event.preventDefault();
    dispatch(formTokko("Nuevo suscriptor newsletter", null));
    document.getElementById("formContact").reset();
    setText(
      "¡Felicitaciones! ahora recibirás todas nuestras novedades."
    );
  };


  const captchaCheck = () => {
    setDisabled(false)
  };

  const currentYear = new Date().getFullYear();

  return (
    <div>
      {location[2] > 0 ? (
        <footer className={(dark ? "dark" : "") + " ficha"}>
          <div className="container-fluid" id="ficha-footer">
            <div className="d-inline-flex align-items-center justify-content-start position-relative">
              <img
                className={"logo-ficha " + (!dark ? "d-block" : "d-none")}
                src={settings.base_url + "assets/images/logo-header.svg"}
                alt=""
              />
              <img
                className={"logo-ficha hola " + (dark ? "d-block" : "d-none")}
                src={settings.base_url + "assets/images/logo-header-dark.svg"}
                alt=""
              />
              <div className="separator"></div>
              <h6 className="mb-0" style={{fontFamily:"Proto Grotesk",fontSize:".8rem"}}>El valor de la confianza.</h6>
            </div>
            
          </div>
        </footer>
      ) : curLocation == "Contacto" || curLocation == "contacto" ? (
        ""
      ) : (
        <footer className={dark ? "dark" : ""}>
          <div className="container-fluid">
            <div className="row position-relative">
              <div className=" col-lg-6 d-none d-lg-flex align-items-start flex-column pb-4">
                <Link
                  to="/"
                  className={
                    "navigator mb-auto " + (curLocation == "" ? "active" : "")
                  }
                >
                  INICIO{" "}
                  <div className="hover">
                    <i className="icon-details"></i>
                  </div>
                </Link>
                <Link
                  to={settings.base_url + "desarrollos"}
                  className={
                    "navigator mb-auto " +
                    (curLocation == "desarrollos" ? "active" : "")
                  }
                >
                  DESARROLLOS
                  <div className="hover">
                    <i className="icon-details"></i>
                  </div>
                </Link>
                <Link
                  to={settings.base_url + "servicios"}
                  className={
                    "navigator mb-auto " +
                    (curLocation == "servicios" ? "active" : "")
                  }
                >
                  SERVICIOS{" "}
                  <div className="hover">
                    <i className="icon-details"></i>
                  </div>
                </Link>
                <Link
                  to={settings.base_url + "nosotros"}
                  className={
                    "navigator mb-auto " +
                    (curLocation == "nosotros" ? "active" : "")
                  }
                >
                  NOSOTROS{" "}
                  <div className="hover">
                    <i className="icon-details"></i>
                  </div>
                </Link>
                <Link
                  to={settings.base_url + "novedades"}
                  className={
                    "navigator mb-auto " +
                    (curLocation == "blog" ? "active" : "")
                  }
                >
                  NOVEDADES{" "}
                  <div className="hover">
                    <i className="icon-details"></i>
                  </div>
                </Link>
                <Link
                  to={settings.base_url + "contacto"}
                  className={
                    "navigator  pb-0" +
                    (curLocation == "contacto" ? "active" : "")
                  }
                >
                  CONTACTO{" "}
                  <div className="hover">
                    <i className="icon-details"></i>
                  </div>
                </Link>
              </div>
              <div className="col-lg-6 d-flex justify-content-start flex-column">
                <form onSubmit={resetForm} id="formContact">
                  <div className="content-newsletter d-flex align-items-stretch">
                    <input
                      type="text"
                      name="email"
                      onChange={handleInputChange}
                      placeholder="Suscribite a nuestro Newsletter ingresando tu mail"
                      required={true}
                    />
                    <button type="submit" disabled={disabled} className="content-arrow">
                      <i className="icon-arrow"></i>
                    </button>
                  </div>
                </form>

                {/* <div className="g-recaptcha d-none d-lg-block" data-sitekey={settings.keys.captcha} data-callback="enableBtn"></div> */}
                    <ReCAPTCHA
                      sitekey={settings.keys.captcha}
                      className="d-none d-lg-block"
                      onChange={captchaCheck}
                    />
                  <div>
                  <span
                    id="text-form"
                    className={(text == "" ? "d-none" : " d-inline-block mt-4 mb-2")}
                  >
                    {text}
                  </span>
                </div>
                <div className="text-center d-block d-lg-none">
                  <img
                    className={"logo-footer mx-auto " + (dark ? "d-block" : "d-none")}
                    src={settings.base_url + "assets/images/logo-header-dark.svg"}
                    alt=""
                  />
                  <img
                    className={"logo-footer mx-auto " + (!dark ? "d-block" : "d-none")}
                    src="assets/images/logo-header.svg"
                    alt=""
                  />
                  <Link
                    to={settings.base_url + ""}
                    className={
                      "navigator mb-auto " + (curLocation == "" ? "active" : "")
                    }
                  >
                    INICIO
                  </Link>
                  <Link
                    to={settings.base_url + "desarrollos"}
                    className={
                      "text-center navigator mb-auto " +
                      (curLocation == "desarrollos" ? "active" : "")
                    }
                  >
                    DESARROLLOS
                  </Link>
                  <Link
                    to={settings.base_url + "servicios"}
                    className={
                      "text-center navigator mb-auto " +
                      (curLocation == "servicios" ? "active" : "")
                    }
                  >
                    SERVICIOS
                  </Link>
                  <Link
                    to={settings.base_url + "nosotros"}
                    className={
                      "text-center navigator mb-auto " +
                      (curLocation == "nosotros" ? "active" : "")
                    }
                  >
                    NOSOTROS
                  </Link>
                  <Link
                    to={settings.base_url + "novedades"}
                    className={
                      "text-center navigator mb-auto " +
                      (curLocation == "blog" ? "active" : "")
                    }
                  >
                    NOVEDADES
                  </Link>
                  <Link
                    to={settings.base_url + "contacto"}
                    className={
                      "text-center navigator mb-auto " +
                      (curLocation == "contacto" ? "active" : "")
                    }
                  >
                    CONTACTO
                  </Link>
                </div>
                <div className="contact-data d-none d-lg-flex flex-column gap-1 wordSpacing">
                  <span>{settings.location.address}</span>
                  
                  <span>{settings.location.region}</span>
                  
                  <span className="mt-1">{settings.contact.phone}</span>
                  
                  <a
                    className="mailto d-none d-lg-block"
                    href={"mailto:" + settings.contact.mail}
                  >
                    {settings.contact.mail}
                  </a>
                </div>
                <div className="social-media d-flex justify-content-center justify-content-lg-start">
                  <a target="_blank" className="single" href={settings.social.facebook}>
                    <i className="icon-facebook"></i>
                  </a>
                  <a target="_blank" className="single" href={settings.social.instagram}>
                    <i className="icon-instagram"></i>
                  </a>
                  <a target="_blank" className="single" href={settings.social.linkedin}>
                    <i className="icon-linkedin"></i>
                  </a>
                  <a target="_blank" className="single" href={settings.social.twitter}>
                    <i className="icon-twitter"></i>
                  </a>
                  <a target="_blank" className="single" href={settings.social.youtube}>
                    <i className="icon-youtube"></i>
                  </a>
                </div>
                <div className="pb-4">
                  <p className="copy"> Promoted by  <a className="mediahaus-link" target="_blank" href="https://mediahaus.com.ar/"> MediaHaus  </a>  - Powered by <a className="mediacore-link" target="_blank" href="http://mediacore.app">MediaCore </a><br/><span className="reservated">Todos los derechos reservados ® {currentYear} </span></p>
                </div>
                  {/* <div className="bubbleContainer">
                    <img src={ChatBubble} className="chatBubble" />
                  </div> */}
              </div>
            </div>
          </div>
        </footer>
      )}
    </div>
  );
}

export default Footer;
