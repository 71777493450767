import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Image2 from "../../assets/images/kitchen-1.png";
import Image3 from "../../assets/images/living-1.png";
import Image4 from "../../assets/images/building-5.png";
import Play from "../../assets/images/play.png";
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
// import DateTimeInput from '../../../Globals/DateTimeInput'

export default function Novelty() {
  const settings = useSelector((store) => store.settings);
  const dark = useSelector((store) => store.theme.dark);
  const novelty = useSelector((store) => store.novelties.novelty);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [content, setContent] = useState('');

  const [socialOpen,setSocialOpen] = useState(false)

  useEffect(() => {
    if(novelty){
      if(novelty.content){
        const blocksFromHtml = htmlToDraft(novelty.content);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        setEditorState(EditorState.createWithContent(contentState))
      }
    }
  },[novelty])


  return novelty ? (
    <div className={"novelty " + (dark ? ' dark-theme ' : '')}>
      <div className="container-fluid">
        <div className="title-section">
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <h1>
              {novelty.title ? novelty.title : "Cargando.."}
            </h1>
            <Link to={`${settings.base_url}novedades`} className="black-link d-none d-lg-block">
              Ver más novedades+
            </Link>
          </div>

          <div className="novelty-date my-2">
            <span>{novelty.publish_date ? novelty.publish_date.split("T")[0].split("-")[1] : "00"}</span>
            <div></div>
            <span>{novelty.publish_date ? novelty.publish_date.split("T")[0].split("-")[0] : "2021"}</span>
          </div>
        </div>
        <div className="d-lg-flex justify-content-between w-100">
          <h2>
            {novelty.excerpt ? novelty.excerpt : 'Cargando..'}{" "}
          </h2>
          <div className="social-novelty d-flex align-items-center">
            <div className={"order-lg-2 order-1 hidden-social d-lg-flex d-none align-items-start justify-content-center " + (socialOpen ? 'open' : '')}>
                <a target="_blank" className="icon-whatsapp small" href={"https://api.whatsapp.com/send?text=" + "Hola! Te comparto la siguiente novedad que te puede interesar: " + location.href}></a>
                <a target="_blank" className="icon-sendmail small" href={'mailto:?subject=' + "Novedad ASPA Patagonia: " +novelty.title + '&body=Hola! Te comparto la siguiente novedad que te puede interesar: ' + location.href}></a>
            </div>
            <a onClick={() => setSocialOpen(!socialOpen)} className="icon-share order-lg-2 order-1"></a>
            <div className={"order-lg-2 order-1 hidden-social d-flex d-lg-none align-items-start justify-content-center " + (socialOpen ? 'open' : '')}>
                <a target="_blank" className="icon-whatsapp small" href={"https://api.whatsapp.com/send?text=" + "Hola! Te comparto la siguiente novedad que te puede interesar: " + location.href}></a>
                <a target="_blank" className="icon-sendmail small" href={'mailto:?subject=' + "Novedad ASPA Patagonia: " +novelty.title + '&body=Hola! Te comparto la siguiente novedad que te puede interesar: ' + location.href}></a>
            </div>
          </div>
        </div>
        
        {/* // Image */}
        <> 
        {
          novelty.image ? 
            <div style={{backgroundImage:"url(https://dev.mediacore.com.ar" + novelty.image + ')'}} className="mt-2 mb-5 fix-height"></div>
          :
          <>
            Cargando imagen..
            <br />
          </>
       }
        </>

        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          // onEditorStateChange={this.onEditorStateChange}
          disabled={true}
        />

        <div className="d-flex justify-content-start align-items-center flex-wrap">
          {novelty.tags ? novelty.tags.map((tag, index) => (
            <div className="tag mb-2">{tag.name}</div>
          )) : 'Cargando etiquetas...'}
        </div>

        <div className="d-flex justify-content-lg-end justify-content-center mt-lg-5 mt-5 mb-5 mb-lg-0 ">
          <Link to={`${settings.base_url}novedades`} className="black-link d-flex">
            Ver más novedades+
          </Link>
        </div>
     
      </div>
    </div>
  )
   : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> );
}
