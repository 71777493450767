import React from "react";
import { useSelector } from "react-redux";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

function Oportunity() {
  const { data } = useSelector((store) => store.developments.development);
  const dark = useSelector((store) => store.theme.dark);

  const options = {
    settings: {
      autoplaySpeed: 1500,
      transitionSpeed: 900,
    },
    buttons: {
      iconColor: "#ffff",
    },
    caption: {
      captionColor: "#fff",
      captionFontFamily: "Raleway, sans-serif",
      captionFontWeight: "300",
      captionTextTransform: "uppercase",
    },
    progressBar: {
      height: "20px",
      fillColor: "#191919",
      backgroundColor: "white",
    },
  };

  var currentLoad = 0;
  return data ? (
    <div className={"oportunity " + (dark ? 'dark-theme' : '')}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-none d-md-block">
            <h5 className="title-3-white ">OPORTUNIDAD:</h5>
          </div>
          <div className="col-lg-7 col-9 mb-2 d-none d-md-block">
            <p className="paragraph-1 mt-1 mb-0 mb-md-5">
              {data.publication_title}
            </p>
          </div>
          <div className={"col-lg-6 ps-lg-2 pe-lg-1 mb-2"}>
              {/* <a href={item.original}> */}
              <div className="noClickeable">
                <img src={data.photos.find(item => item.is_front_cover).original} alt="" />
              </div>
            </div>
          {data.photos.map((item, index) =>
            !item.is_blueprint && !item.is_front_cover && currentLoad < 3
              ? (currentLoad++,
                (
                  <div
                    key={item.original}
                    className={"col-lg-6 ps-lg-2 pe-lg-1 mb-2"}
                  >
                    {/* <a href={item.original}> */}
                    <div className="noClickeable">
                      <img src={item.original} alt="" />
                    </div>
                  </div>
                ))
              : null
          )}
        </div>

        <SimpleReactLightbox>
          <SRLWrapper options={options}>
            {data.photos.map((item, index) =>
              !item.is_blueprint && !item.is_front_cover ? (
                <div
                  className={"col-12 " + (index == 1 ? "d-block" : "d-none")}
                >
                  <div className="open_gallery">
                    <img src={item.original} className="notVisible" alt="" />{" "}
                    <span className="d-flex align-items-center">
                      {" "}
                      <div class="icon me-2"></div>
                      <span> Ver galería de imágenes</span>
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )
            )}
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
    </div>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
}

export default Oportunity;
