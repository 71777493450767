import { Provider } from "react-redux";
import generateStore from "./redux/store";

import Inicio from "./views/home.jsx";
import Servicios from "./views/services.jsx";
import Emprendimientos from "./views/developments.jsx";
import Emprendimiento from "./views/development.jsx";
import Contacto from "./views/contact.jsx";
import Novedades from "./views/novelties";
import Novedad from "./views/novelty";
import Favoritos from "./views/favorites";

// Bool para producción-local
const production = true;
let routePath = "";
if (!production) {
  routePath = "/clientes/aspa";
}
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Header from "./components/header.jsx";
import ScrollToTop from "./components/funcScrollToTop.jsx";
import Footer from "./components/footer.jsx";
import UsMain from "./components/nosotros/usMain";
import ProgressBar from "./components/progressBar";

import { Helmet, HelmetProvider } from 'react-helmet-async';

function App() {
  const store = generateStore();

  return (
    <Router>
      <ScrollToTop />
      <HelmetProvider>
        <Provider store={store}>
          <Header />
          <ProgressBar />
          <Switch>
            <Route path={routePath + "/"} exact>
              <Inicio />
            </Route>
            <Route path={routePath + "/desarrollos"} exact>
              <Emprendimientos />
            </Route>
            <Route path={routePath + "/desarrollos/:id/:title"} exact>
              <Emprendimiento />
            </Route>
            <Route path={routePath + "/servicios"} exact>
              <Servicios />
            </Route>
            <Route path={routePath + "/nosotros"} exact>
              <UsMain />
            </Route>
            <Route path={routePath + "/novedades"} exact>
              <Novedades />
            </Route>
            <Route path={routePath + "/novedad/:slug"} exact>
              <Novedad />
            </Route>
            <Route path={routePath + "/blog"} exact>
              <div className="my-5 text-center">Blog</div>
            </Route>
            <Route path={routePath + "/contacto"} exact>
              <Contacto />
            </Route>
            <Route path={routePath + "/favoritos"} exact>
              <Favoritos />
            </Route>
          </Switch>
          <Footer />
        </Provider>
      </HelmetProvider>
    </Router>
  );
}

export default App;
