import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";


function About() {
  const dark = useSelector((store) => store.theme.dark);
  return (
    <div className={"about " + (dark ? "dark-theme" : "")}>
      <div className="section_indicator">
        <div className="container-fluid  d-flex align-items-center justify-content-between">
          <span>NOSOTROS</span>
          <span>QUIÉNES SOMOS </span>
        </div>
      </div>
      <div className="container-fluid">
        <h2 className="title-2">
          Un <div className="underline d-inline-block">equipo</div> al servicio{" "}
          <br />
          de nuestros <div className="underline d-inline-block"> clientes </div>
          , <br />
          colaboradores y comunidades <br />
        </h2>
        <p className="paragraph-1">
          Aspa desarrolla productos inmobiliarios caracterizados por la
          conjunción <br className="d-lg-block d-none" />
          de innovación, calidad y servicios. Orientada a complacer las
          necesidades <br className="d-lg-block d-none" />
          actuales y potenciales de sus clientes e inversores, acerca las
          posiciones <br className="d-lg-block d-none" />
          de todos los actores, estructura el negocio, y lo presenta a las
          partes <br className="d-lg-block d-none" />
          para lograr sinergia y consenso.
        </p>
        <Link className="link-1" to="nosotros">¡QUIERO SABER MÁS!</Link>
      </div>
    </div>
  );
}

export default About;
