import axios from 'axios'

// constantes
const dataInicial = {
    data:{
        nombre:'',
        apellido:'',
        cellphone:'',
        phone:'',
        email:'',
        work_name:'',
        text:'',
        properties:'',
        tags:''
    }
}


// types
const LOAD_FIELDS = 'LOAD_FIELDS'
const CLEAN_FIELDS = 'CLEAN_FIELDS'

// reducer
export default function contactReducer(state = dataInicial, action){
    switch(action.type){
        case LOAD_FIELDS:
            return {...state, data : action.payload }
        case CLEAN_FIELDS:
            return {...state, 
            data : {
                nombre:'',
                apellido:'',
                cellphone:'',
                phone:'',
                email:'',
                work_name:'',
                text:'',
                properties:'',
                tags:''
            }
        }
        default:
            return state
    }
}


// actions

export const formTokko = (tag,property = '',id='') => async (dispatch, getState) => {
    const fields = getState().contact.data
    try {
        // Campos del formulario //
        // let body = '' 
        // var tags = [tag , 'Sitio Web']
        // if(fields.phone){body += "\n Telefono: " + fields.phone}
        // if(tag == 'Emprendimiento'){body += "\n Emprendimiento: " + property}
        // body += "\n Email: " + fields.email
        // if(fields.text){ body += "\n Mensaje: " + fields.text}
        let body = ''
        var name = ''
        var tags = ['Sitio Web']
        if(tag !== 'Emprendimiento' ){
            tags.push(tag)
        }
        if(property){tags.push(property)}
        if(fields.nombre){name+= fields.nombre}
        if(fields.apellido){name+=" " + fields.apellido}
        // if(fields.phone){body += "\n Telefono: " + fields.phone}
        if(tag == 'Emprendimiento' ){body += "\n Emprendimiento: " + property}
        if(tag == 'Propiedad' ){body += "\n Propiedad: " + property; }
        body += "\n Email: " + fields.email
        if(fields.text){ body += "\n Mensaje: " + fields.text}
        
        var message=''
        if(tag == "Emprendimiento"){
            message =  JSON.stringify({
                name,
                email: fields.email,
                tags,
                phone: fields.phone ? fields.phone : '',
                developments:id,
                text: body
            });
        }
        else{
            message =  JSON.stringify({
                name,
                email: fields.email,
                tags,
                properties:id,
                phone: fields.phone ? fields.phone : '',
                text: body
            });
        }
        // const message =  JSON.stringify({
        //     name: fields.nombre + " " + fields.apellido, 
        //     phone: fields.phone ,
        //     email: fields.email,
        //     work_name:'',
        //     properties:fields.properties,
        //     tags:[tag , 'Sitio Web'],
        //     text: body
        // });

        await axios.post('https://tokkobroker.com/api/v1/webcontact/?key=' + getState().settings.keys.tokko , message ,{
            headers: {
                'Content-Type': 'application/json'
            }})
    } catch (error) {
        console.log(error)
    }
}

export const updateFields = (datos) => async (dispatch, getState) => {
    console.log(datos)
    try {
        dispatch({
            type: LOAD_FIELDS,
            payload: datos
        })
    } catch (error) {
        console.log(error)
    }
}

export const cleanFields = () => async (dispatch, getState) => {
    try {
        dispatch({
            type: CLEAN_FIELDS
        })
    } catch (error) {
        console.log(error)
    }
}