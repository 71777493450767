import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function NoveltyItem({
  image,
  id,
  title,
  description,
  slug,
  url = "/novedad",
  isOutstanding = false,
  featured = false,
}) {
  const settings = useSelector((store) => store.settings);
  return (
    <div className={`novelty-content-item position-relative ${isOutstanding?"":""}` + (featured ? " featured " : '')}>
      <img src={image} alt="" />
      <Link
        to={settings.base_url + "novedad/" + slug}
        className="hover position-absolute " 
      >
        <div className="top">
          <h2>{title}</h2>
        </div>
        <div className="bottom">
          <div className="d-flex flex-wrap flex-md-nowrap justify-content-between">
            <p>{description}</p>
            {/* <a href="#">+</a> */}
            <Link to={settings.base_url + "novedad/" + slug}>+</Link>
          </div>
        </div>
      </Link>
    </div>
  )
}
