import React, { useState, useEffect } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import { getFavoritesAction,clearFavoritesAction } from '../../redux/developmentsDucks'

function Main (){
    const [height,setHeight] = useState(null)
    const [flag,setFlag] = useState(false)
    const stateCodes = useSelector(store => store.developments.stateCodes)
    const {data} = useSelector(store => store.developments.development)
    const settings = useSelector(store => store.settings)
    const dispatch = useDispatch()
    const dark = useSelector((store) => store.theme.dark);

    useEffect(() => {
        data && !flag ?  setHeight(window.innerHeight - document.getElementById('heightText').clientHeight) + setFlag(true) : null
    });

    function makeShortLocation(text){
        const spliter = text.split('|')
        return spliter[1] + ' | ' + spliter[2]
    }

    const addFavorites = (id,type,inmobiliaria) =>{
        if(localStorage.getItem(type + "_favorito_" + inmobiliaria )){
            const storage = localStorage.getItem(type + "_favorito_" + inmobiliaria )
              var array_storage = storage.split("-")
              array_storage = array_storage.filter(element => element != "")
              if(array_storage.find(element => element === id.toString())){
                  localStorage.setItem(type + "_favorito_" + inmobiliaria ,"")
                  var array_new = (array_storage.filter(element => element != id.toString()))
                  for (let index = 0; index < array_new.length; index++) {
                      localStorage.setItem(type + "_favorito_" + inmobiliaria , (localStorage.getItem(type + "_favorito_" + inmobiliaria )  + array_new[index] + "-"))
                  }
                  return null
              }
              else{
                  localStorage.setItem(type + "_favorito_" + inmobiliaria , (localStorage.getItem(type + "_favorito_" + inmobiliaria ) +  id + "-"))
              }
        }
        else{
            localStorage.setItem(type + "_favorito_" + inmobiliaria , id + "-")
        }
          return null
    }
    //Función para checkear un favorito
    const checkFavorite = (id,type,inmobiliaria) => {
    var storage = localStorage.getItem(type + "_favorito_" + inmobiliaria ).split("-") // Recupero los ids
    storage = storage.filter(element => element !== "") // Elimino si hay algun espacio vacio
    if(storage.find(element => element.toString() === id.toString())){
        return true;
    }
    return false
    }

    const checkActive = (id) => {
        setListFavorites(getfavorites("dev","aspa"))
        console.log(listFavorites)
        dispatch(clearFavoritesAction())
        for (const iterator of getfavorites("dev","aspa")) {
            //Por cada uno de los favoritos, traigo su respectiva data de la api
            dispatch(getFavoritesAction(iterator))
        }
    } 

    const getfavorites = (type,inmobiliaria) => {
        if(localStorage.getItem(type + "_favorito_" + inmobiliaria)){
            var storage = localStorage.getItem(type + "_favorito_" + inmobiliaria ).split("-") // Recupero los ids
            storage = storage.filter(element => element !== "") // Elimino si hay algun espacio vacio
            return storage //Retorno array de ids
        }
        return []
    }

    const [listFavorites,setListFavorites] = useState(getfavorites("dev",settings.short_name))


    return data ? (
        <div className={"main " + (dark ? 'dark-theme' : '')}>
            <div className="container-fluid">
                <div className="row d-lg-flex flex-row justify-content-stretch">
                    <div className="col-12 px-0 px-lg-2">
                        <div className="background" id="background" style={{backgroundImage: "url(" + data.photos[1].original + ")", height : height}}/>
                    </div>
                    <div className="col-lg-12 d-lg-flex justify-content-between" id="heightText">
                        <div className="text-center text-md-start">
                            <h3 className="title-1">{data.name}</h3>
                            <button onClick={() => addFavorites(data.id,"dev","aspa",checkActive())} className="save-favorite ms-2 " >
                                {/* {console.log(checkFavorite(data.id,"dev","aspa"))} */}
                                <i className={"icon-star2 me-1 " + (checkFavorite(data.id,"dev","aspa") ? ' selected ' : '')}></i>
                                GUARDAR EN FAVORITOS
                            </button>
                        </div>
                        
                        <div className="text-lg-end text-center text-md-start" id="text-main">
                            <p className="text-uppercase mb-1">Estado: {stateCodes[data.construction_status].name}</p>
                            <p className="animation-bottom">{makeShortLocation(data.location.full_location)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default Main
