import { getDevelopmentsAction } from '../redux/developmentsDucks';
import { getFiltersDevelopmentsAction } from '../redux/developmentsDucks';
import React, { useState, useRef,useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getFavoritesAction,clearFavoritesAction } from '../redux/developmentsDucks'


function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
      return null;
}

function Favoritos(){
    const dispatch = useDispatch();

    const statusSelect = useRef(null);
    const statusType = useRef(null);
    const statusLocation = useRef(null);
  
    // let [countMax, setCountMax] = useState(8);
    let [expanded, setExpanded] = useState(false);
    const stateCodes = useSelector((store) => store.developments.stateCodes);
    const data = useSelector((store) => store.developments.favorites);
    // const { data } = useSelector((store) => store.developments.grid_developments);
    function getPortada(array) {
      return array[1] ? array[1] : '';
    }
    function makeShortLocation(text) {
      const spliter = text.split("|");
      return spliter[1] + " | " + spliter[2];
    }
    const getfavorites = (type,inmobiliaria) => {
      if(localStorage.getItem(type + "_favorito_" + inmobiliaria)){
          var storage = localStorage.getItem(type + "_favorito_" + inmobiliaria ).split("-") // Recupero los ids
          storage = storage.filter(element => element !== "") // Elimino si hay algun espacio vacio
          return storage //Retorno array de ids
      }
      return []
    }
    //Funcion para listar/eliminar favoritos en localStorage
    const addFavorites = (id,type,inmobiliaria) =>{
      if(localStorage.getItem(type + "_favorito_" + inmobiliaria )){
          const storage = localStorage.getItem(type + "_favorito_" + inmobiliaria )
            var array_storage = storage.split("-")
            array_storage = array_storage.filter(element => element != "")
            if(array_storage.find(element => element === id.toString())){
                localStorage.setItem(type + "_favorito_" + inmobiliaria ,"")
                var array_new = (array_storage.filter(element => element != id.toString()))
                for (let index = 0; index < array_new.length; index++) {
                    localStorage.setItem(type + "_favorito_" + inmobiliaria , (localStorage.getItem(type + "_favorito_" + inmobiliaria )  + array_new[index] + "-"))
                }
                return null
            }
            else{
                localStorage.setItem(type + "_favorito_" + inmobiliaria , (localStorage.getItem(type + "_favorito_" + inmobiliaria ) +  id + "-"))
            }
      }
      else{
          localStorage.setItem(type + "_favorito_" + inmobiliaria , id + "-")
      }
        return null
    }
    //Función para checkear un favorito
    const checkFavorite = (id,type,inmobiliaria) => {
      var storage = localStorage.getItem(type + "_favorito_" + inmobiliaria ).split("-") // Recupero los ids
      storage = storage.filter(element => element !== "") // Elimino si hay algun espacio vacio
      if(storage.find(element => element.toString() === id.toString())){
        return true;
      }
      return false
    }
    const checkActive = (id) => {
      setListFavorites(getfavorites("dev","aspa"))
      console.log(listFavorites)
      dispatch(clearFavoritesAction())
      for (const iterator of getfavorites("dev","aspa")) {
          //Por cada uno de los favoritos, traigo su respectiva data de la api
          dispatch(getFavoritesAction(iterator))
      }
  } 
  useEffect(() => {
      dispatch(clearFavoritesAction())
      for (const iterator of getfavorites("dev","aspa")) {
          dispatch(getFavoritesAction(iterator))
      }
  },[])

  useEffect(() => {
    const getDevs = () => {
        dispatch(getDevelopmentsAction())
        dispatch(getFiltersDevelopmentsAction())
    }
    getDevs()
}, [])


  ScrollToTopOnMount()
  const [listFavorites,setListFavorites] = useState(getfavorites("dev","aspa"))


  const normalizeText = (text) => {
    return text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g,"-")
  }

    return(
        <div className="favoritos"  style={{paddingTop:"25vh",paddingBottom:"15vh"}}>
            <div className="container-fluid">
            <h2 className="title-3 title-3-white text-white">FAVORITOS</h2>
            {data ? (
                <div className="grid-development pb-5 mt-lg-5">
                  {data.length > 0 ?
                    <div className="row no-gutters ps-lg-2">
                    {data.map((development, index) => (
                      <div className={ " col-lg-3 pe-lg-1 mb-3 animate__animated animate__fadeInUp "} key={index} >
                        <Link to={"desarrollos/" + development.data.id + "/" + normalizeText(development.data.name)} className="text-decoration-none">
                          <div className="card-grid">
                            <div
                              style={{
                                backgroundImage:
                                  "url(" +
                                  (development.data.photos[0]
                                    ? getPortada(development.data.photos).original
                                    : "https://www.trueking.org/static/media/no-product-image.31b3d017.png") +
                                  ")",
                              }}
                              className="img"
                            ></div>
                            <div className="hover-text">
                              <h5>{development.data.publication_title}</h5>
                              <p className="text-uppercase">
                                Estado:{" "}
                                {stateCodes[development.data.construction_status].name}
                              </p>
                              <p className="animation-bottom">
                                {makeShortLocation(
                                  development.data.location.full_location
                                )}
                              </p>
                              <Link
                                className=""
                                to={"desarrollos/" + development.data.id + "/" + normalizeText(development.data.name)}
                              >
                                +
                              </Link>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                  </div>
                    :
                    <>
                      <h5 className="text-white ms-2">
                        No se han encontrado resultados para su búsqueda.  
                      </h5>
                    </>  
                }

                </div>
              ) : (
                <div className="col-1">
                  <div className="loading-section development">
                    <div className="lds-dual-ring"></div>{" "}
                  </div>
                </div>
              )}
        </div>
        </div>

    )
}

export default Favoritos
