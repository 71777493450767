import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    if(!localStorage.getItem("prop"+"_favorito_"+"aspa")){
      localStorage.setItem("prop"+"_favorito_"+"aspa",'');
    }
    if(!localStorage.getItem("dev"+"_favorito_"+"aspa")){
        localStorage.setItem("dev"+"_favorito_"+"aspa",'');
    }
  },[])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}