import React, { useState } from "react";
import { useSelector } from "react-redux";
import ReactPlayer from "react-player";

function Details() {
  const { data } = useSelector((store) => store.developments.development);
  const [moreText, setMoreText] = useState(7);
  const dark = useSelector((store) => store.theme.dark);

  function getShortDescription(text) {
    if (moreText == 99) return text.split('\n');
    let array = text.split(".", moreText);
    let res = [];
    for (let index = 0; index < array.length; index++) {
      res += array[index] + ". ";
    }
    return res.split('\n');
    // + array[1]  +  '. '
  }


  const getVideos = (property) => {
    return(property.videos.filter(element => element?.provider?.toLowerCase() == 'youtube'))
  }
  
  const getTour = (property) => {
    return(property.videos.filter(element => element?.provider?.toLowerCase() == "matterport"))
  }

  return data ? (
    <div className={"details " + (dark ? 'dark-theme':'')}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="section_indicator d-flex align-items-center justify-content-between">
              <span>{data.publication_title}</span>
              <span>SOBRE EL PROYECTO </span>
            </div>
          </div>
          <div className="col-lg-12 mb-lg-5 mb-3">
            <p>
              {
              getShortDescription(data.description, moreText).map((text, index) => (
                <span key={index}><span>{text}</span><br/></span>
              ))}
              {moreText == 99 ? (
                <button className="" onClick={() => setMoreText(7)}>
                  Ver menos...
                </button>
              ) : (
                <button className="" onClick={() => setMoreText(99)}>
                  Ver mas...
                </button>
              )}
            </p>
          </div>
          <div className="col-lg-12">
            <div className="row no-gutters">
              {data.tags.map((item) => {
                return (
                  <div
                    key={item.id}
                    className="item col-6 col-md-4 px-lg-0 d-inline-flex align-items-start pe-0 pe-lg-2 align-items-lg-center"
                  >
                    <i className="icon-details me-lg-2 me-1"></i>
                    {item.name}
                  </div>
                );
              })}
            </div>
          </div>
          {getVideos(data).length > 0 ? (
            <div className="col-12 my-5">
              <h2
                style={{ fontWeight: 300 }}
                className="title-3-white text-uppercase mt-4 mb-4"
              >
                {getVideos(data)[0].title}
              </h2>
              <ReactPlayer
                url={getVideos(data)[0].player_url}
                playing={true}
                width="100%"
                height="70vh"
                controls={false}
                // muted
                playIcon={<img src="../../assets/images/play.png" />}
                light={
                  "https://img.youtube.com/vi/" +
                  getVideos(data)[0].video_id +
                  "/maxresdefault.jpg"
                }
              />
            </div>
          ) : (
            ""
          )}
          {getVideos(data).length > 1 ? (
            <div className="col-12 my-5">
              <h2
                style={{ fontWeight: 300 }}
                className="title-3-white text-uppercase mt-4 mb-4"
              >
                {getVideos(data)[1].title}
              </h2>
              <ReactPlayer
                url={getVideos(data)[1].player_url}
                playing={true}
                width="100%"
                height="70vh"
                controls={false}
                // muted
                playIcon={<img src="../../assets/images/play.png" />}
                light={
                  "https://img.youtube.com/vi/" +
                  getVideos(data)[1].video_id +
                  "/maxresdefault.jpg"
                }
              />
            </div>
          ) : (
            ""
          )}
          {getTour(data).length > 0 ? (
            <div className="col-12 my-lg-5 mb-5" id="tour">
              <h2
                style={{ fontWeight: 300 }}
                className="title-3-white text-uppercase mt-4 mb-4"
              >
                {getTour(data)[getTour(data).length-1].title}
              </h2>
              <iframe style={{height:"50vh",width:"100%"}} src={getTour(data)[getTour(data).length-1].url}></iframe>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="loading-section">
      <div className="lds-dual-ring"></div>{" "}
    </div>
  );
}

export default Details;
