import React from 'react'
import {useSelector} from 'react-redux'
import Map from '../map'
import { GoogleMap, Marker } from "react-google-maps"

function brLocation(text){
    const res = text.split(',')
    return res
}

function cityLocation(text){
    const res = text.split(' | ')
    return res
}

function Location (){
    const {data} = useSelector(store => store.developments.development)
    const dark = useSelector((store) => store.theme.dark);

    return data ? (
        <div className={"location " + (dark ? 'dark-theme' : '') }>
            <div className="container-fluid">
                <div className="d-lg-flex justify-content-between align-items-start w-100">
                    <h3>UBICACIÓN</h3>
                    <h5 className="text-lg-end">{data.address} <br /> {cityLocation(data.location.full_location)[2]} - {cityLocation(data.location.full_location)[1]} </h5>
                    {/* <h5 className="text-lg-end">{data.address}</h5> */}
                </div>

                <Map />

            </div>
        </div>
    ) : (<div className="loading-section"><div className="lds-dual-ring"></div> </div> )
}

export default Location
