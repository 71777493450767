import React , { useState, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { updateFields } from '../redux/contactDucks'

function Formulario(){
    const dispatch = useDispatch();
    const {data} = useSelector((store) => store.contact);


    const handleInputChange = (event) => {
        dispatch(updateFields({
            ...data,
            [event.target.name]: event.target.value
        }))
    }
 

    useEffect(( ) => {
        console.log(data)
    },[data])
    return(
        <div>  
            <div className="d-lg-flex justify-content-between">
                <input type="text" name="nombre" className="me-lg-4 me-lg-3" placeholder="NOMBRE *" onChange={handleInputChange} required /> 
                <input type="text" name="apellido" className="ms-lg-4 ms-lg-3" placeholder="APELLIDO *" onChange={handleInputChange} required /> 
            </div>
            <input type="text" name="phone" placeholder="TELÉFONO" onChange={handleInputChange}/> 
            <input type="mail" name="email" placeholder="MAIL *" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" onChange={handleInputChange} required /> 
            <textarea rows="5" type="text" name="text" placeholder="MENSAJE *" required onChange={handleInputChange}/> 
            <div className="d-flex justify-content-end">
                <span className="obligatory">* Campos obligatorios</span>
            </div>
        </div>
    )
}

export default Formulario