import React, { useState, useRef,useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  updateFiltersDevelopment,
  cleanDevelopmentAction,
  getFiltersDevelopmentsAction,
} from "../../redux/developmentsDucks";

function Hero() {
  const dispatch = useDispatch();

  const statusSelect = useRef(null);
  const statusType = useRef(null);
  const statusLocation = useRef(null);

  const [dinamicTypes,setTypes] = useState([{id:0,name:"Tipo"}]); 
  const [dinamicLocations,setLocation] = useState([{id:0,full_location:"Ciudad"}]); 

  let [countMax, setCountMax] = useState(8);
  let [expanded, setExpanded] = useState(false);
  const [filters, setFilters] = useState({ type: 0, status: "", location: "" });
  const stateCodes = useSelector((store) => store.developments.stateCodes);
  const { data } = useSelector((store) => store.developments.grid_developments);
  const developments = useSelector((store) => store.developments.developments).data;

  function getPortada(array) {
    return array[1] ? array[1] : '';
  }

  
  function makeShortLocationFilter(text) {
    if(text.full_location === "Ciudad" )return "Ciudad";
    const spliter = text.full_location.split("|");
    return text.name + " | " + spliter[1]; 
  }

  function makeShortLocation(text) {
    const spliter = text.split("|");
    return spliter[1] + " | " + spliter[2];
  }

  function updateFilters() {
    dispatch(cleanDevelopmentAction(1));
    dispatch(
      updateFiltersDevelopment(
        statusType.current.value,
        statusLocation.current.value,
        statusSelect.current.value
      )
    );
    dispatch(getFiltersDevelopmentsAction());
  }

  const normalizeText = (text) => {
    return text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/ /g,"-")
  }

  useEffect(() => {
    if(developments && dinamicTypes.length === 1){
      let newsTypes = [{id:'',name:"Tipo"}]
      for (const iterator of developments.objects) {
        if(newsTypes.filter(element => element.id.toString() === iterator.type.id.toString()).length === 0){
          newsTypes.push(iterator.type)
        }
      }
      setTypes(newsTypes)
    }
    if(developments && dinamicLocations.length === 1){
      let newLocations = [{id:'',full_location:"Ciudad"}]
      for (const iterator of developments.objects) {
        if(newLocations.filter(element => element.id.toString() === iterator.location.id.toString()).length === 0){
          newLocations.push(iterator.location)
        }
      }
      setLocation(newLocations)
    }
  },[developments])


  return (
    <section className="section-grid pb-5">
      <div className="border-bottom">
        <div className="container-fluid">
          <div className="row no-gutters">
            <div className="col-5">
              <div className="section-name">
                <h5>Desarrollos</h5>
              </div>
            </div>
            <div className="col-5">
              <div className="section-name d-flex align-items-center">
                <h5 className="d-none d-lg-block">nuestros desarrollos</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <h2 className="mt-4 mb-3 mb-md-5 d-inline-block title-3 text-center text-md-start">
          CONOCÉ TODOS
          <br className="d-none d-lg-block" />
          NUESTROS DESARROLLOS
        </h2>
        <div className="row align-items-center">
          <div className="col-8">
          <form action="#" className="row no-gutters ps-lg-2 w-100">
            <div className="col-lg-4 pe-lg-1">
              <div className="content-select">
                <select
                  name="state"
                  ref={statusSelect}
                  onChange={() => updateFilters()}
                  id=""
                >
                  <option value="">Estado actual</option>
                  {stateCodes.map((statusCode, index) =>
                    statusCode.id >= 0 ? (
                      <option key={statusCode.id} value={statusCode.id}>
                        {statusCode.name}
                      </option>
                    ) : (
                      ""
                    )
                  )}
                </select>
                <i></i>
              </div>
            </div>
            <div className="col-lg-4 pe-lg-1">
              <div className="content-select">
                <select
                  name="city"
                  ref={statusLocation}
                  onChange={() => updateFilters()}
                  id=""
                >
                  {dinamicLocations.map((location, index) =>(
                    <option value={location.id} key={location.id}>{makeShortLocationFilter(location)}</option>
                  ))}
                  {/* <option value="80459">Neuquen | Capital</option>
                  <option value="240331">Rio Negro | Cipolleti</option> */}
                </select>
                <i></i>
              </div>
            </div>
            <div className="col-lg-4 pe-lg-1">
              <div className="content-select">
                <select
                  name="type"
                  ref={statusType}
                  onChange={() => updateFilters()}
                  id=""
                >
                  {
                    dinamicTypes ? dinamicTypes.map((type, index) => (
                    <option value={type.id}>{type.name}</option>
                  )):''}

                </select>
                <i></i>
              </div>
            </div>
          </form>
          </div>
          {data ? (
            <div className="grid-development pb-5">
              {data.meta.total_count > 0 ?
                <div className="row no-gutters ps-lg-2">
                {data.objects.map((development, index) => (
                  <div
                    className={
                      (index < countMax ? "d-block" : "d-none") +
                      " col-lg-3 pe-lg-1 mb-3 animate__animated animate__fadeInUp "
                    }
                    key={index}
                  >
                    <Link
                      to={"desarrollos/" + development.id  + "/" + normalizeText(development.name)}
                      className="text-decoration-none"
                    >
                      <div className="card-grid">
                        <div
                          style={{
                            backgroundImage:
                              "url(" +
                              (development.photos[0]
                                ? getPortada(development.photos).original
                                : "https://www.trueking.org/static/media/no-product-image.31b3d017.png") +
                              ")",
                          }}
                          className="img"
                        ></div>
                        {/* <img src={development.photos[0] ?  getPortada(development.photos).original : 'https://www.trueking.org/static/media/no-product-image.31b3d017.png'} alt={development.location.name}/> */}
                        <div className="hover-text">
                          <h5>{development.name}</h5>
                          <p className="text-uppercase">
                            Estado:{" "}
                            {stateCodes[development.construction_status].name}
                          </p>
                          <p className="animation-bottom">
                            {makeShortLocation(
                              development.location.full_location
                            )}
                          </p>
                          {console.log(development)}
                          <Link
                            className=""
                            to={"desarrollos/" + development.id + "/" + normalizeText(development.name)}
                          >
                            +
                          </Link>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
                <div className="col-12 text-center">
                  {(() => {
                    switch (true) {
                      case data.objects.length > countMax:
                        return (
                          <button
                            onClick={() =>
                              setCountMax(countMax + 4) + setExpanded(true)
                            }
                            className="link-2-dark"
                          >
                            MOSTRAR MÁS
                          </button>
                        );
                        break;
                      case data.objects.length < countMax && expanded:
                        return (
                          <button
                            onClick={() =>
                              setCountMax((countMax = 8)) + setExpanded(false)
                            }
                          >
                            MOSTRAR MENOS
                          </button>
                        );
                        break;
                      default:
                        return "";
                    }
                  })()}
                </div>
              </div>
                :
                <>
                  <h5 className="text-white ms-2">
                    No se han encontrado resultados para su búsqueda.  
                  </h5>
                </>  
            }

            </div>
          ) : (
            <div className="col-1">
              <div className="loading-section development">
                <div className="lds-dual-ring"></div>{" "}
              </div>
            </div>
          )}
        </div>
        </div>
    </section>
  );
  //
}

export default Hero;
