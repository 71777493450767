import React , {useEffect} from 'react'
import Novelty from '../components/novelties/novelty'
import { useParams } from "react-router-dom";
import { getNoveltieAction } from '../redux/noveltiesDucks'
import { useDispatch } from 'react-redux';


function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

function Novedades(){
  const dispatch = useDispatch();
  const {slug } = useParams();

  useEffect(() => {
    const getNovelty = () => {
      dispatch(getNoveltieAction(slug));
    };
    getNovelty();
  }, []);

  ScrollToTopOnMount();    
  return(
        <div className="Novedad">
          <Novelty/>
        </div>
    )
}

export default Novedades
