import { Link, useLocation, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanDevelopmentAction } from "../redux/developmentsDucks";
import { changeThemeAction } from "../redux/themeDucks";

function Header() {
  let location = useLocation().pathname.split("/");
  let curLocation = location[1];
  const dark = useSelector((store) => store.theme.dark);
  const settings = useSelector((store) => store.settings);
  const [statusNav, setStatusNav] = useState("");
  // Navigator function //
  function toggleNav() {
    switch (statusNav) {
      case "open": {
        setStatusNav("");
        break;
      }
      case "": {
        setStatusNav("open");
        break;
      }
    }
  }

  useEffect(() => {
    if(!localStorage.getItem("prop"+"_favorito_"+"aspa")){
      localStorage.setItem("prop"+"_favorito_"+"aspa",'');
    }
    if(!localStorage.getItem("dev"+"_favorito_"+"aspa")){
        localStorage.setItem("dev"+"_favorito_"+"aspa",'');
    }
  },[])

  const dispatch = useDispatch();

  return (
    <header className={dark ? "dark" : ""}>
      {(location[1] === 'desarrollos' && location[2] > 0)? (
        <nav className="d-flex align-items-center justify-content-between">
          <div className="container-fluid d-flex align-items-center justify-content-between">
            <div className="section-header">
              {/* Desarrollo de ASPA  */}
              {/* DESARROLLOS PATAGONIA <br className="d-block d-lg-none" />{" "} */}
              Producto de ASPA <br className="d-block d-lg-none" />{" "} DESARROLLOS PATAGONIA
            </div>
            <li className="d-flex align-items-center">
              <Link
                className="back-button"
                onClick={
                  (() => useHistory().goBack(),
                  () => dispatch(cleanDevelopmentAction(2)))
                }
                to={settings.base_url + "desarrollos"}
              >
                {" "}
                VOLVER A ASPA {">"}
              </Link>
            </li>
          </div>
        </nav>
      ) : (
        <nav className="d-flex align-items-center justify-content-between">
          <div className="container-fluid d-flex justify-content-between">
            <div className="border-side right d-flex align-items-center">
              <Link to={settings.base_url}>
                <img
                  className={("logo-header", dark ? "d-none" : "d-block")}
                  src={settings.base_url + "assets/images/logo-header.svg"}
                  alt=""
                />
              </Link>
              <Link to={settings.base_url}>
                <img
                  className={("logo-header", !dark ? "d-none" : "d-block")}
                  src={settings.base_url + "assets/images/logo-header-dark.svg"}
                  alt=""
                />
              </Link>
            </div>
            <div className="middle-half text-right w-100 align-items-center">
              <div className="section-name text-right justify-content-end align-items-center d-flex">
                <h5>{curLocation == "" ? "Inicio" : curLocation}</h5>
              </div>
              <div className="theme-selector d-flex align-items-center justify-content-end">
                <div
                  onClick={() => dispatch(changeThemeAction(!dark))}
                  className={"container-icon d-flex align-items-center"}
                >
                  <h5 className={!dark ? "d-block" : "d-none"}>Modo oscuro</h5>
                  <i
                    className={"icon-night " + (!dark ? "d-block" : "d-none")}
                  />
                  <h5 className={dark ? "d-block" : "d-none"}>Modo claro</h5>
                  <i
                    className={"icon-light " + (dark ? "d-block" : "d-none")}
                  />
                </div>
                <div
                  // onClick={() => dispatch(changeThemeAction(false))}
                  className={"container-icon d-flex align-items-center "}
                >
                  <Link style={{textDecoration: "none",marginBottom:'0',lineHeight:'0'}} to ={ settings.base_url + "favoritos"}>
                    <i className={"icon-star2 " + (location[1] == "favoritos" ? " selected" : "")}  />
                  </Link>

                </div>
              </div>
            </div>
            <div className="border-side left d-flex align-items-center">
              <div className={"img " + statusNav} onClick={() => toggleNav()}>
                <i></i>
                <i></i>
                <i></i>
              </div>
              {/* <img  src="assets/images/menu.svg" alt=""/> */}
            </div>
          </div>
          <div className={"menu-links " + statusNav}>
            <div className="container-fluid pe-0 pe-lg-3">
              <div className="d-lg-flex w-100 justify-content-between">
                {/* <div className="col-lg-8"> */}
                <ul className="ps-0">
                  <li>
                    <Link
                      onClick={() => toggleNav()}
                      to={settings.base_url + ""}
                      className={
                        "navigator mb-auto " +
                        (curLocation == "" ? "active" : "")
                      }
                    >
                      INICIO{" "}
                      <div className="hover">
                        <i className="icon-details"></i>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => toggleNav()}
                      to={settings.base_url + "desarrollos"}
                      className={
                        "navigator mb-auto " +
                        (curLocation == "desarrollos" ? "active" : "")
                      }
                    >
                      DESARROLLOS
                      <div className="hover">
                        <i className="icon-details"></i>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => toggleNav()}
                      to={settings.base_url + "servicios"}
                      className={
                        "navigator mb-auto " +
                        (curLocation == "servicios" ? "active" : "")
                      }
                    >
                      SERVICIOS
                      <div className="hover">
                        <i className="icon-details"></i>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => toggleNav()}
                      to={settings.base_url + "nosotros"}
                      className={
                        "navigator mb-auto " +
                        (curLocation == "nosotros" ? "active" : "")
                      }
                    >
                      NOSOTROS
                      <div className="hover">
                        <i className="icon-details"></i>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => toggleNav()}
                      to={settings.base_url + "novedades"}
                      className={
                        "navigator mb-auto " +
                        (curLocation == "blog" ? "active" : "")
                      }
                    >
                      NOVEDADES
                      <div className="hover">
                        <i className="icon-details"></i>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={() => toggleNav()}
                      to={settings.base_url + "contacto"}
                      className={
                        "navigator mb-auto " +
                        (curLocation == "contacto" ? "active" : "")
                      }
                    >
                      CONTACTO
                      <div className="hover">
                        <i className="icon-details"></i>
                      </div>
                    </Link>
                  </li>
                </ul>
                {/* </div>
                                <div className="col-lg-4 mt-auto"> */}
                <div className="social-media mt-lg-auto d-flex justify-content-start justify-content-lg-start">
                  <a target="_blank" className="single" href={settings.social.facebook}>
                    <i className="icon-facebook"></i>
                  </a>
                  <a target="_blank" className="single" href={settings.social.instagram}>
                    <i className="icon-instagram"></i>
                  </a>
                  <a target="_blank" className="single" href={settings.social.linkedin}>
                    <i className="icon-linkedin"></i>
                  </a>
                  <a target="_blank" className="single" href={settings.social.twitter}>
                    <i className="icon-twitter"></i>
                  </a>
                  <a target="_blank" className="single" href={settings.social.youtube}>
                    <i className="icon-youtube"></i>
                  </a>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </nav>
      )}
    </header>
    );
}

export default Header;
